<template>
  <div class="flex flex-col gap-y-3">
    <label v-if="formElement.properties.label">{{ formElement.properties.label }} </label>
    <div class="flex flex-col gap-3 md:flex-row">
      <template v-for="(item, index) in formElement.properties.items" :key="index">
        <VcRadioButton v-model="model" :name="formElement.key" :value="item.value" :label="item.caption" />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount } from "vue";
import type { ExtendedFormElement } from "../types";

interface IProps {
  formElement: ExtendedFormElement;
}

const props = defineProps<IProps>();
const model = defineModel<string>();

onBeforeMount(() => {
  model.value = props.formElement.properties.items.find((item) => item.checked)?.value;
});
</script>
