import { SortDirection } from "@/core/enums";
import type { ISortInfo } from "@/core/types/search/sorting";

export function getSortingExpression(sort: ISortInfo): string {
  return `${sort.column}:${sort.direction}`;
}

/**
 * @deprecated Use Sort.toggle() instead
 */
export function toggleSortDirection(currentDirection: string): string {
  return currentDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
}
