import { useThemeContext } from "@/core/composables";
import { useUser } from "@/shared/account";
import type { RouteRecordRaw } from "vue-router";

const Dashboard = () => import("@/pages/account/dashboard.vue");
const Profile = () => import("@/pages/account/profile.vue");
const AccountInfo = () => import("@/pages/account/account-info.vue");
const Addresses = () => import("@/pages/account/addresses.vue");
const Orders = () => import("@/pages/account/orders.vue");
const OrderDetails = () => import("@/pages/account/order-details.vue");
const OrderPayment = () => import("@/pages/account/order-payment.vue");
const Lists = () => import("@/pages/account/lists.vue");
const ListDetails = () => import("@/pages/account/list-details.vue");
const CheckoutDefaults = () => import("@/pages/account/checkout-defaults.vue");
const Quotes = () => import("@/pages/account/quotes.vue");
const EditQuote = () => import("@/pages/account/edit-quote.vue");
const ViewQuote = () => import("@/pages/account/view-quote.vue");
const Notifications = () => import("@/pages/account/notifications.vue");
const Impersonate = () => import("@/pages/account/impersonate.vue");
const ProductNotifications = () => import("@/pages/account/product-notification.vue");
const SavedShoppingCarts = () => import("@/pages/account/saved-shopping-carts.vue");
const SavedShoppingCartsDetails = () => import("@/pages/account/saved-shopping-carts-detail.vue");
const RemoveAccount = () => import("@/pages/account/remove-account.vue");
const ContactUs = () => import("@/pages/account/contact-us.vue");
const SitePreferences = () => import("@/pages/thorlabs/site-preferences.vue");

const { themeContext } = useThemeContext();

export const accountRoutes: RouteRecordRaw[] = [
  { path: "dashboard", name: "Dashboard", component: Dashboard },
  // { path: "change-password", name: "ChangePasswordAccount", component: ChangePassword },
  { path: "account-info", name: "AccountInfo", component: AccountInfo },
  { path: "profile", name: "Profile", component: Profile },
  { path: "remove-account", name: "RemoveAccount", component: RemoveAccount },
  { path: "contact-us", name: "ContactUs", component: ContactUs },
  // { path: "my-account", name: "MyAccount", component: MyAccount },
  { path: "site-preferences", name: "SitePreferences", component: SitePreferences },
  {
    path: "addresses",
    name: "Addresses",
    component: Addresses,
    beforeEnter(_to, _from, next) {
      const { isCorporateMember } = useUser();

      if (!isCorporateMember.value) {
        next();
      } else {
        next({ name: "Dashboard" });
      }
    },
  },
  {
    path: "orders",
    children: [
      { path: "", name: "Orders", component: Orders },
      {
        path: ":orderId",
        children: [
          {
            path: "",
            name: "OrderDetails",
            component: OrderDetails,
            props: true,
          },
          {
            path: "payment",
            name: "OrderPayment",
            component: OrderPayment,
            props: true,
            meta: { layout: "Secure" },
          },
        ],
        meta: { hideLeftSidebar: true },
      },
    ],
  },
  {
    path: "lists",
    children: [
      { path: "", name: "Lists", component: Lists },
      {
        path: ":listId",
        name: "ListDetails",
        component: ListDetails,
        props: true,
      },
    ],
  },
  { path: "checkout-defaults", name: "CheckoutDefaults", component: CheckoutDefaults },
  {
    path: "quotes",
    children: [
      { path: "", name: "Quotes", component: Quotes },
      {
        path: ":quoteId",
        children: [
          {
            path: "",
            name: "ViewQuote",
            component: ViewQuote,
            props: true,
            meta: { hideLeftSidebar: true },
          },
          {
            path: "edit",
            name: "EditQuote",
            component: EditQuote,
            props: true,
          },
        ],
      },
    ],
    beforeEnter(_to, _from, next) {
      if (themeContext.value.settings.quotes_enabled) {
        next();
      } else {
        next({ name: "Dashboard" });
      }
    },
  },
  {
    path: "saved-shopping-carts",
    children: [
      {
        path: "",
        name: "SavedShoppingCarts",
        component: SavedShoppingCarts,
      },
      {
        path: ":cartId/details",
        name: "SavedShoppingCartsDetails",
        component: SavedShoppingCartsDetails,
        props: true,
      },
    ],
  },
  {
    path: "product-notifications",
    name: "ProductNotifications",
    component: ProductNotifications,
    // beforeEnter(_to, _from, next) {
    //   if (themeContext.value?.settings?.push_messages_enabled) {
    //     next();
    //   } else {
    //     next({ name: "Dashboard" });
    //   }
    // },
  },
  {
    path: "notifications",
    name: "Notifications",
    component: Notifications,
    beforeEnter(_to, _from, next) {
      if (themeContext.value?.settings?.push_messages_enabled) {
        next();
      } else {
        next({ name: "Dashboard" });
      }
    },
  },
  {
    path: "impersonate/:userId",
    name: "Impersonate",
    props: true,
    component: Impersonate,
  },
];
