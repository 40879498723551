<template>
  <template v-if="!target || isMailto">
    <a
      class="footer-link text-sm font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
      :href="href"
    >
      <slot>{{ title }}</slot>
    </a>
  </template>
  <template v-else>
    <a
      class="footer-link text-sm font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
      :href="href"
      :target="target"
      rel="noopener noreferrer"
    >
      <slot>{{ title }}</slot>
    </a>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface IProps {
  href?: string;
  title?: string;
  target?: string;
}

const props = defineProps<IProps>();

const isMailto = computed(() => props.href?.startsWith("mailto:"));
</script>

<style lang="scss">
.footer-link {
  @apply text-xs font-medium text-[--footer-top-link-color];

  &:hover {
    @apply text-[--footer-top-link-hover-color];
  }

  &.router-link-active {
    @apply text-[--footer-top-link-active-color];
  }
}
</style>
