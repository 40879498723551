/**
 * The list of constants is the keyword used for cached values
 */
export var FormConstants;
(function (FormConstants) {
    FormConstants["FormAccessToken"] = "form_access_token";
    FormConstants["FormFieldPrefix"] = "__field_";
    FormConstants["FormCurrentStep"] = "form_current_step_";
    FormConstants["FormSubmissionId"] = "form_submission_id_";
})(FormConstants || (FormConstants = {}));
