<template>
  <div class="flex flex-wrap justify-center lg:gap-x-3">
    <a
      v-for="link in socialMediaLinks"
      :key="link.link"
      target="_blank"
      class="rounded-[50%]"
      :class="['shrink-0 p-2 text-[color:var(--color-footer-top-text)] hover:transition hover:[&_img]:scale-125']"
      :href="link.link"
      :aria-label="`Share on ${capitalize(removeFileExtension(link.icon))}`"
    >
      <img :src="`/static/thorlabs/socials/${link.icon}`" alt="" role="presentation" class="size-6" lazy />
    </a>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import type { ISocialMediaLink } from "@/shared/thorlabs/blocks";

defineProps<{
  socialMediaLinks: ISocialMediaLink[] | undefined;
}>();

function removeFileExtension(filename: string): string {
  return filename.split(".").slice(0, -1).join(".");
}

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
</script>
