<template>
  <div class="flex flex-col">
    <VcAlert color="success" size="lg" variant="outline-dark" class="mb-6" icon closable>
      KM100 {{ $t("common.messages.minicart_success") }}
    </VcAlert>

    <div class="header__top flex-auto">
      <!-- Headers -->
      <div v-if="!isMobile" class="mb-5 flex border-b pb-4">
        <span class="flex flex-[0_0_50%] text-base font-semibold">
          {{ $t("shared.product.notifications.page.notification_add_item_placeholder") }}
        </span>
        <span class="header__top-qty flex justify-center text-base font-semibold">
          {{ $t("shared.cart.rapid_order.add_item_rapid_order_item_quantity_label") }}
        </span>
        <span class="flex flex-1 justify-end text-base font-semibold">
          {{ $t("shared.cart.add_bulk_items_to_cart_results_modal.labels.amount") }}
        </span>
      </div>
      <div
        v-for="item in availabilityItems"
        :key="item.lineItem.id"
        class="cart__item mb-5 flex flex-auto flex-col border-b pb-5 md:pb-4"
      >
        <div :class="{ 'flex flex-auto gap-4': true, disabled: isDisabled }" :style="{ opacity: isDisabled ? 0.5 : 1 }">
          <div class="cart__item-image flex size-[80px] items-center md:size-[100px]">
            <VcImage
              :src="item.lineItem.imageUrl"
              alt=""
              multiple-dpr
              :default-size="DEFAULT_DETAIL_THUMB_SIZE"
              :width="DEFAULT_THUMB_SIZE"
              :height="DEFAULT_THUMB_SIZE"
            ></VcImage>
          </div>
          <div class="flex w-full flex-col md:flex-row md:gap-8">
            <div class="cart__item-details flex flex-1 flex-col">
              <a
                class="flex items-center gap-1.5 whitespace-nowrap font-semibold text-[--color-link] hover:text-[--color-neutral-a1]"
                :href="getProductFamillyPageUrl(item.lineItem)"
                >{{ item.lineItem.sku }}</a
              >
              <span v-if="item.lineItem.product?.altItemIdTL && item.lineItem.product?.altItemIdTL.length > 0"
                >{{ $t("shared.cart.replaced") }} {{ item.lineItem.sku }}</span
              >
              <span>{{ item.lineItem.product?.description?.content }}</span>
              <p
                v-dompurify-html="item.lineItem.product?.descriptions.find((d) => d.reviewType === 'Title')?.content"
                class="line-clamp-1 overflow-hidden text-ellipsis text-base font-semibold leading-6"
              ></p>
              <div v-if="item.lineItem.listPrice && isMobile" class="mb-0 mt-2 md:my-0 md:mb-1">
                <span class="mt-2 text-base font-normal md:text-sm">
                  {{ $t("shared.catalog.product_details.product_variations.availability") }}: {{ item.status }}
                </span>
              </div>
              <div v-if="item.lineItem.listPrice" class="price-per-unit flex justify-between md:justify-normal">
                <div class="flex flex-col gap-1 md:gap-0">
                  <!-- Promo price logic, displayed only if discountTotal exists and is greater than or equal to 0 -->
                  <div
                    v-if="
                      item.lineItem.salePrice.amount > 0 &&
                      item.lineItem.listPrice?.amount > item.lineItem.salePrice.amount
                    "
                  >
                    <span class="text-base text-[--color-neutral-a1] md:text-sm">
                      <VcPriceDisplay :value="item.lineItem.salePrice!" />
                      {{ $t("shared.cart.per_unit") }}
                    </span>
                  </div>

                  <!-- Original price logic -->
                  <span
                    :class="{
                      '': true, // Base styles
                      'text-base text-[--color-neutral-a2] line-through md:text-sm':
                        item.lineItem.salePrice.amount > 0 &&
                        item.lineItem.listPrice?.amount > item.lineItem.salePrice.amount, // Apply line-through if there's a discount
                    }"
                  >
                    {{ item.lineItem.listPrice.formattedAmount }} {{ $t("shared.cart.per_unit") }}
                  </span>
                </div>
                <div v-if="isMobile" class="cart__item-price flex flex-col items-end gap-1 md:gap-0">
                  <!-- Promo price logic, displayed only if discountTotal exists and is greater than or equal to 0 -->
                  <div
                    v-if="
                      item.lineItem.salePrice.amount > 0 &&
                      item.lineItem.listPrice?.amount > item.lineItem.salePrice.amount
                    "
                  >
                    <span class="price-promo text-base font-bold">
                      <VcPriceDisplay :value="item.lineItem.salePrice!" />
                    </span>
                  </div>

                  <!-- Original price logic -->
                  <span
                    :class="{
                      'price text-base font-bold': true, // Base styles
                      'font-normal text-[--color-neutral-a2] line-through':
                        item.lineItem.salePrice.amount > 0 &&
                        item.lineItem.listPrice?.amount > item.lineItem.salePrice.amount, // Apply line-through if there's a discount
                    }"
                  >
                    {{ item.lineItem.listPrice.formattedAmount }}
                  </span>
                </div>
              </div>

              <!-- TODO - needs to be implemented -->
              <div class="cart__serial-number mt-2 text-sm leading-4 md:mt-1">
                <span v-if="item.lineItem.itemNumberTL && item.lineItem.itemNumberTL.length > 0" class="">
                  {{ $t("shared.cart.item") }} #: {{ item.lineItem.itemNumberTL }}
                </span>
                <span
                  v-if="
                    item.lineItem.serialNumberTL &&
                    item.lineItem.serialNumberTL.length > 0 &&
                    item.lineItem.itemNumberTL &&
                    item.lineItem.itemNumberTL.length > 0
                  "
                  class="mx-2 text-[--color-neutral-a3]"
                  >|</span
                >
                <span v-if="item.lineItem.serialNumberTL && item.lineItem.serialNumberTL.length > 0">
                  {{ $t("shared.cart.serial") }} #: {{ item.lineItem.serialNumberTL }}
                </span>
              </div>

              <div v-if="!isMobile" class="mt-3 flex gap-4">
                <!-- Remove button -->
                <button
                  :aria-label="$t('shared.cart.remove_from_cart')"
                  type="button"
                  class="flex items-center text-sm font-normal hover:underline"
                  @click="removeCartItem(item.lineItem.id)"
                >
                  <VcIcon name="trash-2" :size="20" class="mt-1" />
                  <span> {{ $t("shared.cart.remove_from_cart") }}</span>
                </button>
              </div>
            </div>
            <div
              class="cart__item-quantity mt-4 flex items-center justify-between md:mt-0 md:items-start md:justify-normal"
            >
              <div v-if="isMobile" class="flex gap-8">
                <!-- Remove button -->
                <button
                  :aria-label="$t('shared.cart.remove_from_cart')"
                  type="button"
                  class="flex items-center text-sm font-normal hover:underline"
                  @click="removeCartItem(item.lineItem.id)"
                >
                  <VcIcon name="trash-2" :size="20" class="mt-1" />
                  <span v-if="!isMobile"> {{ $t("shared.cart.remove_from_cart") }}</span>
                </button>
              </div>

              <VcQuantity
                :model-value="item.lineItem.quantity"
                :name="item.lineItem.id"
                class="w-[180px] md:w-[132px]"
                @update:model-value="changeItemQuantity(item.lineItem.id, $event)"
              >
                <template #prepend>
                  <VcButton
                    :aria-label="$t('common.buttons.search_quote_requests')"
                    icon="minus"
                    color="tertiary"
                    variant="outline"
                    class="qty-left w-[60px] md:w-[44px]"
                    size="md"
                    @click="decreaseQuantity(item.lineItem)"
                  />
                </template>
                <template #append>
                  <VcButton
                    :aria-label="$t('common.buttons.search_quote_requests')"
                    icon="plus-alt-thin"
                    color="tertiary"
                    variant="outline"
                    class="qty-right w-[60px] md:w-[44px]"
                    size="md"
                    @click="increaseQuantity(item.lineItem)"
                  />
                </template>
              </VcQuantity>
            </div>
            <div v-if="item.lineItem.listPrice && !isMobile" class="cart__item-price flex flex-col items-end">
              <!-- Promo price logic, displayed only if discountTotal exists and is greater than or equal to 0 -->
              <div
                v-if="
                  item.lineItem.salePrice.amount > 0 && item.lineItem.listPrice?.amount > item.lineItem.salePrice.amount
                "
              >
                <span class="price-promo text-base font-bold">
                  <VcPriceDisplay :value="item.lineItem.salePrice!" />
                </span>
              </div>

              <!-- Original price logic -->
              <span
                :class="{
                  'price text-base font-bold': true, // Base styles
                  'font-normal text-[--color-neutral-a2] line-through':
                    item.lineItem.salePrice.amount > 0 &&
                    item.lineItem.listPrice?.amount > item.lineItem.salePrice.amount, // Apply line-through if there's a discount
                }"
              >
                {{ item.lineItem.listPrice.formattedAmount }}
              </span>
              <span class="mt-2 text-sm font-normal">
                {{ $t("shared.catalog.product_details.product_variations.availability") }}: {{ item.status }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-end font-bold">
    {{ $t("common.labels.subtotal") }} ({{ cart?.itemsCount }} {{ $t("common.labels.items") }}):
    {{ cart?.subTotal?.formattedAmount }}
  </div>

  <div class="mt-6 md:mt-14">
    <VcButton
      v-bind="$attrs"
      type="button"
      class="flex w-full"
      color="primary"
      variant="solid"
      aria-label="Shopping cart"
      @click="handleCheckoutClick"
    >
      {{ $t("common.labels.view_cart_checkout") }}
    </VcButton>

    <VcButton
      :to="{ name: 'Catalog', replace: true }"
      color="secondary"
      variant="outlineTL"
      size="lg"
      class="mt-4 flex w-full items-center"
      @click="handleButtonClick"
    >
      {{ $t("common.buttons.continue_shopping") }}
    </VcButton>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { onMounted, ref, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useFullCart } from "../composables/useCart";
import type { InputCustomerReferenceType, LineItemTypeExtension } from "@/core/api/graphql/types";
import type { ExtendedMenuLinkType } from "@/core/types";
import type { NavigationFailure } from "vue-router";

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits<{
  (e: "closeDialog"): void;
  (e: "change:itemQuantity", payload: { itemId: string; quantity: number }): void;
}>();

defineProps<IProps>();

interface IProps {
  link?: ExtendedMenuLinkType | undefined;
  count?: number | undefined;
  isActive: boolean;
  href: string;
  navigate: (e?: MouseEvent) => Promise<void | NavigationFailure>;
}

const router = useRouter();

function handleButtonClick() {
  emit("closeDialog");
}

async function handleCheckoutClick() {
  await router.push({ name: "Cart" });
  handleButtonClick();
}

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");
const isDisabled = ref(false); // Update this based on your logic
const { removeItems, forceFetch, changeItemQuantity, cart, availabilityItems } = useFullCart();
const sectionItems = ref<InputCustomerReferenceType[]>([]);

function getProductFamillyPageUrl(item: LineItemTypeExtension) {
  return item.product?.descriptions.find((x) => x.reviewType === "FamilyPageUrl")?.content;
}

function decreaseQuantity(item: LineItemTypeExtension) {
  const inputElement = document.querySelector(`[name="${item.id}"]`) as HTMLInputElement;
  if (inputElement) {
    let quantity: number = parseInt(inputElement.value);
    if (!isNaN(quantity)) {
      quantity -= 1;
      changeItemQuantity(item.id, quantity);
    }
  }
}

function increaseQuantity(item: LineItemTypeExtension) {
  const inputElement = document.querySelector(`[name="${item.id}"]`) as HTMLInputElement;
  if (inputElement) {
    let quantity: number = parseInt(inputElement.value);
    if (!isNaN(quantity)) {
      quantity += 1;
      changeItemQuantity(item.id, quantity);
    }
  }
}

async function removeCartItem(itemId: string) {
  await removeItems([itemId]);
}

onMounted(async () => {
  await forceFetch();
});

defineExpose({
  sectionItems,
});

const DEFAULT_THUMB_SIZE = isMobile.value ? 80 : 100;
const DEFAULT_DETAIL_THUMB_SIZE = isMobile.value ? 80 : 100;
</script>
