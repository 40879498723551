import { SAVED_CART_DEFAULT_PAGE_SIZE } from "@/core/constants";
import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import searchCartQueryDocument from "./getCartsQuery.graphql";
import type { CartExtensionConnection, Query, QueryCartsArgs } from "../../../types";
import type { CartSearchParam } from "@/shared/cart/types/search";

export async function getCarts({
  itemsPerPage = SAVED_CART_DEFAULT_PAGE_SIZE,
  page = 1,
  sort,
  cartType,
  cartName,
  cartExclusion,
}: Partial<CartSearchParam>): Promise<CartExtensionConnection> {
  const { storeId, userId } = globals;
  const { data } = await graphqlClient.query<Required<Pick<Query, "carts">>, QueryCartsArgs>({
    query: searchCartQueryDocument,
    variables: {
      cartName,
      excludeNameKeyword: cartExclusion,
      storeId,
      userId,
      cultureName: "",
      currencyCode: "",
      sort,
      first: itemsPerPage,
      after: String((page - 1) * itemsPerPage),
      cartType,
    },
  });

  return data.carts;
}
