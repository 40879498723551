<template>
  <div class="mb-5 flex flex-col gap-x-6 md:flex-row">
    <div class="flex-1">
      {{ subtitle }}
    </div>
    <div
      class="mt-5 text-nowrap border-t border-[--color-neutral-a4] pt-5 md:mt-0 md:border-l md:border-t-0 md:pl-8 md:pt-0"
    >
      <strong>{{ $t("shared.product.contact_form.contact_info") }}</strong
      ><br />
      {{ $t("common.labels.phone") }}:
      <a
        class="text-[--color-link] hover:text-[--color-neutral-a1]"
        :href="`tel:${footerBlock?.salesPhone}`"
        :title="$t('shared.product.contact_form.call_us')"
        :aria-label="$t('shared.product.contact_form.call_us_at', [footerBlock?.salesPhone])"
        >{{ footerBlock?.salesPhone }}</a
      ><br />
      {{ $t("common.labels.email") }}:
      <a
        class="text-[--color-link] hover:text-[--color-neutral-a1]"
        :href="`mailto:${footerBlock?.salesEmail}`"
        :title="$t('shared.product.contact_form.email_us')"
        :aria-label="$t('shared.product.contact_form.email_us_at', [footerBlock?.salesEmail])"
        >{{ footerBlock?.salesEmail }}</a
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFooterBlock } from "@/core/composables/useFooterBlock";

interface IProps {
  subtitle: string;
}

defineProps<IProps>();

const { footerBlock } = useFooterBlock();
</script>
