import { useApolloClient } from "@vue/apollo-composable";
import { useLocalStorage, createGlobalState } from "@vueuse/core";
import { computed } from "vue";
import getClientTokenQueryDocument from "../api/graphql/clientToken/queries/getClientToken/getClientTokenQuery.graphql";
import type { GetClientTokenQuery } from "../api/graphql/types";

function _useClientAuth() {
  const headers = computed(() => {
    if (state.value.access_token) {
      return {
        Authorization: `${state.value.token_type} ${state.value.access_token}`,
      };
    }
    return {};
  });

  const INITIAL_STATE = Object.freeze({
    expires_at: null,
    token_type: "Bearer",
    access_token: null,
    refresh_token: null,
  });

  const state = useLocalStorage<{
    expires_at: Date | string | null;
    token_type: string;
    access_token: string | null;
    refresh_token: string | null;
  }>("clientAuth", { ...INITIAL_STATE });

  function updateToken(context: GetClientTokenQuery | null) {
    if (context?.clientToken) {
      const { token, tokenType, expiresIn } = context.clientToken;

      if (token && tokenType && expiresIn) {
        setAccessToken(token);
        setTokenType(tokenType);
        setExpiresAt(expiresIn);
      }
    }
  }

  const { client } = useApolloClient();

  const getClientToken = async () => {
    try {
      const { data: token } = await client.query<GetClientTokenQuery>({
        query: getClientTokenQueryDocument,
        fetchPolicy: "no-cache",
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return (token as GetClientTokenQuery) ?? null;
    } catch (error) {
      return null;
    }
  };

  async function authorize(): Promise<void> {
    if (state.value.access_token && !isExpired()) {
      return;
    }
    const tokenResponse = await getClientToken();
    updateToken(tokenResponse);
  }

  function isExpired() {
    if (state.value.expires_at === null) {
      return true;
    }

    return new Date(state.value.expires_at).getTime() <= Date.now();
  }

  function setAccessToken(token: string) {
    state.value.access_token = token;
  }

  function setTokenType(type: string) {
    state.value.token_type = type;
  }

  function setExpiresAt(seconds: number) {
    state.value.expires_at = new Date(Date.now() + seconds * 1000);
  }

  return {
    headers,
    isExpired,
    authorize,
    setAccessToken,
    setExpiresAt,
  };
}

export const useClientAuth = createGlobalState(_useClientAuth);
