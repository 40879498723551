<template>
  <VueHcaptcha
    ref="hCaptchaRef"
    class="captcha"
    :sitekey="siteKey"
    :challenge-container="challengeContainerId"
    :language="culture"
    @verify="verify"
    @opened="showChallenge"
    @expired="hideChallenge"
    @challenge-expired="hideChallenge"
    @closed="hideChallenge"
    @reset="hideChallenge"
  ></VueHcaptcha>
  <input v-model="token" type="hidden" :name="elementKey" />

  <VcInputDetails :message="message" :error="error" />

  <div
    :id="challengeContainerId"
    ref="hCaptchaChallengeRef"
    class="hcaptcha-challenge-container"
    :inert="!challengeVisible"
  ></div>
</template>

<script lang="ts" setup>
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import { onMounted, ref, getCurrentInstance, onBeforeMount, withDefaults, defineProps } from "vue";
import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";

interface IProps {
  elementKey?: string;
  siteKey?: string;
  message?: string;
  error?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  elementKey: "captchaCode",
});
//assigning a default value for siteKey only works like this
const siteKey = ref(props.siteKey || globals.hCaptchaSiteKey);

const hCaptchaRef = ref<InstanceType<typeof VueHcaptcha> | null>(null);
const hCaptchaChallengeRef = ref<HTMLElement | null>(null);

const culture = ref<string>("");
const token = defineModel<string>();
const challengeVisible = ref<boolean>(false);
const challengeContainerId = ref<string>("");

const chinaScript =
  "https://cn1.hcaptcha.com/1/api.js?endpoint=https://cn1.hcaptcha.com&assethost=https://assets-cn1.hcaptcha.com&imghost=https://imgs-cn1.hcaptcha.com&reportapi=https://reportapi-cn1.hcaptcha.com";

onBeforeMount(() => (culture.value = useLanguages().currentLocale.value));

function verify(receivedToken: string): void {
  token.value = receivedToken;
  hideChallenge();
}

const reset = () => {
  hCaptchaRef.value?.reset();
  token.value = "";
  hideChallenge();
};

const hideChallenge = () => {
  challengeVisible.value = false;
};

const showChallenge = () => {
  challengeVisible.value = true;
};

onMounted(() => {
  const instance = getCurrentInstance();
  if (instance) {
    challengeContainerId.value = `hcaptcha-challenge-${instance.uid}`;
  }

  if (window.location.hostname.includes(".cn")) {
    const hCaptchaScriptElement = document.getElementById("hcaptcha-api-script-id");
    hCaptchaScriptElement?.parentNode?.removeChild(hCaptchaScriptElement);
    const tag = document.createElement("script");
    tag.id = "hcaptcha-api-script-id";
    tag.src = chinaScript;
    document.getElementsByTagName("head")[0].appendChild(tag);
  }
});

defineExpose({
  reset,
});
</script>

<style lang="scss">
.hcaptcha-challenge-container {
  @apply rounded-[20px] w-full h-full absolute top-1/2 left-1/2 m-auto -translate-x-1/2 -translate-y-1/2 overflow-auto z-[12] bg-[rgba(0,0,0,0.5)] flex items-center justify-center;

  &[inert] {
    @apply bg-transparent;
  }

  iframe {
    @apply m-auto;
  }
}
</style>
