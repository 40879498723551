import { graphqlClient } from "../../../client";
import queryDocument from "./getMultipleItemsAvailabilityQuery.graphql";
import type {
  Query,
  ProductAvailabilityRequest,
  ProductAvailabilityResponseType,
  QueryMultipleItemsAvailabilityArgs,
} from "@/core/api/graphql/types";

export async function getMultipleItemsAvailability(
  productAvailabilityRequests: ProductAvailabilityRequest[],
): Promise<ProductAvailabilityResponseType[]> {
  const { data } = await graphqlClient.query<
    Required<Pick<Query, "multipleItemsAvailability">>,
    QueryMultipleItemsAvailabilityArgs
  >({
    query: queryDocument,
    variables: {
      productAvailabilityRequests,
    },
  });

  return data.multipleItemsAvailability ?? [];
}
