import Assets from "./assets.vue";
import Description from "./description.vue";
import ProductFamilyInfo from "./product-family-info.vue";
import ProductFormContact from "./product-form-contact.vue";
import ProductImageButtons from "./product-image-buttons.vue";
import Properties from "./properties.vue";
import RecalibrationItemInfo from "./recalibration-item-info.vue";
import Variations from "./variations.vue";
import WarrantyInfo from "./warranty-info.vue";
import type { Component } from "vue";

const templateBlocks: { [key: string]: Component } = {
  "product-assets": Assets,
  "product-description": Description,
  "product-properties": Properties,
  "product-variations": Variations,
  "warranty-info": WarrantyInfo,
  "product-family-info": ProductFamilyInfo,
  "product-image-buttons": ProductImageButtons,
  "recalibration-item-info": RecalibrationItemInfo,
  "product-form-contact": ProductFormContact,
};

export default templateBlocks;
