import { graphqlClient } from "../../../client";
import mutationDocument from "./deleteAccount.graphql";
import type {
  InputDeleteContactTypeExtension,
  Mutations,
  MutationsAnonymizeContactArgs,
} from "@/core/api/graphql/types";

export async function anonymizeContact(contact: InputDeleteContactTypeExtension): Promise<boolean> {
  const response = await graphqlClient.mutate<
    Required<Pick<Mutations, "anonymizeContact">>,
    MutationsAnonymizeContactArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: contact,
    },
  });
  return response.data?.anonymizeContact ?? false;
}
