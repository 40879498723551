export const getFileExtension = (filename: string | undefined) => {
  if (!filename) {
    return "";
  }
  return filename.split(".").pop()?.split("?")[0] ?? "";
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};
