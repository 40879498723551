'use strict';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApiClient } from "./apiClient";
import { defaultConfig } from "./apiClientConfig";
import { ApiConstant } from "./apiConstant";
/**
 * Class for load Form
 */
export class FormLoader {
    /**
     *  Constructor an instance of FormLoader
     *
     * @param config Optional config to use. This config will combined with the defaultConfig.
     */
    constructor(config) {
        this.client = new ApiClient(Object.assign(Object.assign({}, defaultConfig), config));
    }
    /**
     * Get a form with a GUID
     *
     * @param key GUID of form
     * @param language Localization for form
     * @returns A promise with a form
     */
    getForm(key, language) {
        return new Promise((resolve, reject) => {
            this.client.get(`${ApiConstant.apiEndpoint}/${key}`, { locale: language })
                .then((response) => {
                resolve(response);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    /**
     * Query a form with a GUID
     *
     * @param optiGraphUrl The endpoint url of Optimizely Graph
     * @param key GUID of form
     * @param language Localization for form
     * @returns A promise with a form
     */
    queryForm(optiGraphUrl, key, language) {
        return new Promise((resolve, reject) => {
            let query = `
            query FormQuery($key: String, $language: String) {
                FormContainer(where: { Key: { eq: $key }, Locale: { eq: $language } }) {
                    items {
                        Key
                        Locale
                        Properties
                        Localizations
                        FormElements {
                            Key
                            ContentType
                            DisplayName
                            Locale
                            Localizations
                            Properties
                            Locale
                        }
                    }
                }
            }
            `;
            let variables = { key: key, language };
            fetch(optiGraphUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    query,
                    variables,
                }),
            })
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                if (response.ok) {
                    let json = yield response.json();
                    let formStr = json.data.FormContainer.items[0];
                    console.log(formStr);
                    let convertedFormStr = this.convertFirstLetterToLowerCase(formStr);
                    resolve(convertedFormStr);
                }
                else {
                    reject(response);
                }
            }))
                .catch((error) => {
                reject(error);
            });
        });
    }
    /**
     * Function to convert the first letter of object keys to lowercase
     * @param data Data in json format
     * **/
    convertFirstLetterToLowerCase(data) {
        const isObject = typeof data === 'object';
        if (data && isObject && !Array.isArray(data)) {
            return Object.keys(data).reduce((accumulator, key) => {
                const normalizedKey = key.charAt(0).toLowerCase() + key.slice(1);
                accumulator[normalizedKey] = isObject ? this.convertFirstLetterToLowerCase(data[key]) : data[key];
                return accumulator;
            }, {});
        }
        else if (Array.isArray(data)) {
            return data.map(item => this.convertFirstLetterToLowerCase(item));
        }
        return data;
    }
}
