import { useLocalStorage } from "@vueuse/core";
import { computed } from "vue";
import { useUser } from "@/shared/account/composables/useUser";
import { countryCodeToLanguageAndCurrencyMap } from "../constants/language-and-currency";
import FeatureFlags from "../utilities/featureFlags";
import { useThemeContext } from "./useThemeContext";
import type { ICurrency } from "../types";
import type { LanguageAndCurrency } from "../types/defaultLanguageCurrency";

const { themeContext } = useThemeContext();
const { user } = useUser();

const savedCurrencyCode = useLocalStorage<string | null>("currency", "");

const defaultCurrency = computed<ICurrency>(() => themeContext.value.defaultCurrency);
const supportedCurrencies = computed<ICurrency[]>(() => {
  const availableCurrencies = themeContext.value.availableCurrencies;
  const orderedCurrencies = ["USD", "EUR", "GBP", "SEK", "CNY", "JPY", "BRL"];

  return availableCurrencies.sort((a, b) => {
    const indexA = orderedCurrencies.indexOf(a.code);
    const indexB = orderedCurrencies.indexOf(b.code);

    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });
});
const contactCurrency = computed(() =>
  supportedCurrencies.value?.find((item) => item.code === user.value?.contact?.currencyCode),
);

const countryCodeTLDCurrency = computed(() => {
  if (themeContext.value.storeSettings.modules) {
    const featureFlags = new FeatureFlags(themeContext.value.storeSettings.modules);
    const ccTLDsValue = (featureFlags.getValue("Optimizely.Connector", "ccTLDs") as string) || "";
    const domains = ccTLDsValue ? (JSON.parse(ccTLDsValue) as Record<string, LanguageAndCurrency>) : {};

    return domains[location.hostname]?.Currency;
  }

  return null;
});

function getGeolocationCurrency(): string {
  const countryCode = themeContext.value.countryCode as keyof typeof countryCodeToLanguageAndCurrencyMap;

  if (countryCodeToLanguageAndCurrencyMap[countryCode]) {
    return (
      supportedCurrencies.value.find((x) => x.code === countryCodeToLanguageAndCurrencyMap[countryCode].Currency)
        ?.code || defaultCurrency.value.code
    );
  }

  return defaultCurrency.value.code;
}

const currentCurrency = computed<ICurrency>(() => {
  if (contactCurrency?.value && contactCurrency.value?.code) {
    return supportedCurrencies.value.find((item) => item.code === contactCurrency.value?.code) || defaultCurrency.value;
  } else if (savedCurrencyCode.value) {
    return supportedCurrencies.value.find((item) => item.code === savedCurrencyCode.value) || defaultCurrency.value;
  } else if (countryCodeTLDCurrency.value) {
    return (
      supportedCurrencies.value.find((item) => item.code === countryCodeTLDCurrency.value) || defaultCurrency.value
    );
  } else {
    return supportedCurrencies.value?.find((item) => item.code === getGeolocationCurrency()) || defaultCurrency.value;
  }
});

function saveCurrencyCode(code: string, needToReload: boolean = true) {
  savedCurrencyCode.value = code;

  if (needToReload) {
    location.reload();
  }
}

export function useCurrency() {
  return {
    savedCurrencyCode,
    defaultCurrency,
    supportedCurrencies,
    currentCurrency,
    saveCurrencyCode,
  };
}
