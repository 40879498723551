/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { ModuleSettingsType } from "@/core/api/graphql/types";

class FeatureFlags {
  private modules: ModuleSettingsType[];

  constructor(modules: ModuleSettingsType[]) {
    this.modules = modules;
  }

  isFeatureEnabled(moduleId: string, featureName: string) {
    const module = this.modules.find((m) => m.moduleId === moduleId);
    if (module) {
      const feature = module.settings.find((setting) => setting.name === featureName);
      if (feature && feature.value === true) {
        return true;
      }
    }
    return false;
  }

  getValue(moduleId: string, featureName: string) {
    const module = this.modules.find((m) => m.moduleId === moduleId);
    if (module) {
      const feature = module.settings.find((setting) => setting.name === featureName);
      if (feature && feature.value !== undefined) {
        return feature.value;
      }
    }
    return null;
  }

  isModuleEnabled(moduleId: string) {
    const module = this.modules.find((m) => m.moduleId === moduleId);
    return !!module;
  }
}

// eslint-disable-next-line no-restricted-exports
export default FeatureFlags;
