import { createGlobalState } from "@vueuse/core";
import { computed, shallowRef } from "vue";
import { getGetWhiteLabelingSettings } from "@/core/api/graphql/whiteLabeling/queries";
import { Logger, convertToExtendedMenuLink } from "@/core/utilities";
import { useThemeContext } from "./useThemeContext";
import type { WhiteLabelingSettingsType } from "@/core/api/graphql/types";

const whiteLabelingSettings = shallowRef<WhiteLabelingSettingsType>();
const { modulesSettings, themeContext } = useThemeContext();

const MODULE_KEYS = {
  ID: "VirtoCommerce.WhiteLabeling",
  ENABLE_STATE: "WhiteLabeling.WhiteLabelingEnabled",
};

const moduleSettings = computed(() => modulesSettings.value?.find((module) => module.moduleId === MODULE_KEYS.ID));
const moduleEnabled = computed(
  () => moduleSettings.value?.settings?.find((item) => item.name === MODULE_KEYS.ENABLE_STATE)?.value,
);

function _useWhiteLabeling() {
  async function fetchWhiteLabelingSettings(): Promise<void> {
    if (moduleEnabled.value) {
      try {
        whiteLabelingSettings.value = await getGetWhiteLabelingSettings();
      } catch (e) {
        Logger.error(`${_useWhiteLabeling.name}.${fetchWhiteLabelingSettings.name}`, e);
        throw e;
      }
    }
  }

  return {
    logoUrl: computed(() => whiteLabelingSettings.value?.logoUrl ?? themeContext.value?.settings?.logo_image),
    secondaryLogoUrl: computed(
      () => whiteLabelingSettings.value?.secondaryLogoUrl ?? themeContext.value?.settings?.logo_inverted_image,
    ),
    footerLinks: computed(() =>
      whiteLabelingSettings.value?.footerLinks?.map((item) => convertToExtendedMenuLink(item)),
    ),
    favIcons: computed(
      () =>
        whiteLabelingSettings.value?.favicons ?? [
          {
            rel: "icon",
            type: "image/png",
            href: `${themeContext.value?.settings?.favicon_image}?v=1`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: `${themeContext.value?.settings?.favicon_16_image}?v=1`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: `${themeContext.value?.settings?.favicon_32_image}?v=1`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "180x180",
            href: `${themeContext.value?.settings?.favicon_apple_image}?v=1`,
          },
          {
            rel: "mask-icon",
            type: "image/png",
            href: themeContext.value?.settings?.favicon_mask_image,
            color: `${themeContext.value?.settings?.favicon_color_config}?v=1`,
          },
          {
            rel: "manifest",
            href: `${themeContext.value?.settings?.favicon_manifest_image}?v=1`,
          },
        ],
    ),
    themePresetName: computed(() => whiteLabelingSettings.value?.themePresetName),
    fetchWhiteLabelingSettings,
  };
}

export const useWhiteLabeling = createGlobalState(_useWhiteLabeling);
