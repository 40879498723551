<template>
  <Dialog
    ref="dialogRef"
    dialog-mode="medium"
    content-class="dialog-content"
    header-class="font-semibold text-[20px]"
    @close="resetForm"
  >
    <template #title>{{ dialogTitle ?? formContainer?.properties.title }}</template>
    <template #content>
      <div :class="descriptionClass">
        <slot name="description"></slot>
      </div>
      <Form ref="formRef" :key="formKey" :form="formContainer" :product="product" @submitted="closeDialog" />
    </template>
    <template #footer>
      <VcButton color="primary" variant="solid" class="w-full" size="lg" @click="dialogRef && submitForm()">{{
        dialogButton ?? $t("common.buttons.submit")
      }}</VcButton>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";
import { useSlugInfo } from "@/shared/common";
import { FormLoader } from "../forms-sdk";
import Form from "./form.vue";
import type { ExtendedFormContainer } from "../types";
import type { Product } from "@/core/api/graphql/types";
import type { IFormContent } from "@/shared/thorlabs/forms/types";
import Dialog from "@/shared/thorlabs/shared/components/dialog-modal.vue";

interface IProps {
  formName: string;
  product?: Product;
  dialogTitle?: string;
  dialogButton?: string;
  descriptionClass?: string;
}

const props = defineProps<IProps>();

const dialogRef = ref<InstanceType<typeof Dialog> | null>(null);
//this is used to remount the component after submit/close
const formKey = ref(0);

const showDialog = () => {
  dialogRef.value?.openDialog();
};

function closeDialog(): void {
  dialogRef.value?.closeDialog();
}

const { slugInfo, hasContent, contentResult, fetchContent } = useSlugInfo(props.formName);
const formContainer = ref<ExtendedFormContainer | undefined>(undefined);

async function loadForm(): Promise<ExtendedFormContainer | undefined> {
  if (formContent.value) {
    const formLoader = new FormLoader({ baseURL: globals.optimizelyHost + "/" });
    const id = formContent.value.contentLink.guidValue.replace(/-/g, "");
    const culture = useLanguages().currentLocale.value;
    return (await formLoader.getForm(id, culture)) as ExtendedFormContainer;
  }
  return undefined;
}

const formRef = ref<InstanceType<typeof Form> | null>(null);

function submitForm(): void {
  formRef.value?.submit();
}

function resetForm(): void {
  formKey.value++;
}

watch(slugInfo, async () => {
  if (hasContent.value) {
    await fetchContent();
  }
});

const formContent = computed<IFormContent | undefined>(() => {
  try {
    const formData: IFormContent = JSON.parse(contentResult?.value?.page?.content ?? "{}") as IFormContent;
    return formData as IFormContent;
  } catch (error) {
    /* empty */
  }

  return undefined;
});

watch(formContent, async () => {
  if (!formContainer.value) {
    formContainer.value = await loadForm();
  }
});

defineExpose({
  showDialog,
});
</script>
