import { computed, readonly, ref, shallowRef } from "vue";
import { getMelissaAddresses } from "../../../core/api/graphql/common/queries/getMelissaAddresses";
import { validateAddress } from "../../../core/api/graphql/common/queries/validateAddress";
import { Logger } from "../../../core/utilities";
import type {
  AddressValidationResponseType,
  MelissaResultType,
  QueryGetMelissaAddressesArgs,
  QueryValidateMelissaAddressesArgs,
} from "../../../core/api/graphql/types";

export function useMelissaAddresses() {
  const loading = ref(false);
  const melissaAddreses = shallowRef<MelissaResultType[]>([]);

  async function loadMelissaAddresses(payload: QueryGetMelissaAddressesArgs): Promise<MelissaResultType[]> {
    loading.value = true;

    try {
      return await getMelissaAddresses(payload);
    } catch (e) {
      Logger.error(loadMelissaAddresses.name, e);
      return [];
    } finally {
      loading.value = false;
    }
  }

  async function validateMelissaAddress(
    payload: QueryValidateMelissaAddressesArgs,
  ): Promise<AddressValidationResponseType> {
    try {
      return await validateAddress(payload);
    } catch (e) {
      Logger.error(validateAddress.name, e);
      const result: AddressValidationResponseType = {
        success: false,
      };
      return result as AddressValidationResponseType;
    }
  }

  return {
    loadMelissaAddresses,
    validateMelissaAddress,
    loading: readonly(loading),
    melissaAddreses: computed(() => melissaAddreses.value),
  };
}
