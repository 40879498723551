<template>
  <VcTextarea
    v-model="inputValue"
    :required="isRequired"
    :max-length="8000"
    :rows="4"
    :for="formElement.key"
    :label="formElement.properties.label"
    :name="formElement.key"
    autocomplete="off"
    counter
    :message="message"
    :error="error"
    @input="validate"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { FormValidator } from "../forms-sdk";
import type { ElementValidationResult } from "../forms-sdk";
import type { ExtendedFormElement } from "../types";

interface IProps {
  formElement: ExtendedFormElement;
  errorMessage?: ElementValidationResult;
}

interface IEmits {
  (event: "update:error-message", error: ElementValidationResult): void;
}
const emit = defineEmits<IEmits>();

const props = defineProps<IProps>();
const inputValue = ref<string>("");
const elementValidationResult = ref<ElementValidationResult>();
const message = computed(() => elementValidationResult.value?.message || props.errorMessage?.message);
const error = computed(() => !!elementValidationResult.value?.message || !!props.errorMessage?.message);

const isRequired = computed(
  () => !!props.formElement.properties.validators.find((v) => v.type === "RequiredValidator"),
);

const formValidator = new FormValidator(props.formElement);

function validate(event: Event): void {
  const input = event.target as HTMLInputElement;
  elementValidationResult.value = formValidator.validate(input.value);
  emit("update:error-message", elementValidationResult.value);
}
</script>
