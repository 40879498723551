import { graphqlClient } from "../../../client";
import getMelissaAddressesQuery from "./getMelissaAddresses.graphql";
import type { MelissaResultType, QueryGetMelissaAddressesArgs, Query } from "@/core/api/graphql/types";

export async function getMelissaAddresses(payload?: QueryGetMelissaAddressesArgs): Promise<MelissaResultType[]> {
  const { data } = await graphqlClient.query<
    Required<Pick<Query, "getMelissaAddresses">>,
    QueryGetMelissaAddressesArgs
  >({
    query: getMelissaAddressesQuery,
    variables: {
      ...payload,
    },
  });

  return data.getMelissaAddresses.results ?? [];
}
