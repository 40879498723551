'use strict';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Class for making API calls to the Optimizely Headless Form.
 */
export class ApiClient {
    /**
    * Constructs an instance of ApiClient.
    *
    * @param initConfig Configuration to use.
    */
    constructor(initConfig) {
        this.config = initConfig;
    }
    /**
     * Make a request
     *
     * @param path API endpoint url
     * @param method Method type of request, e.g. GET/POST
     * @param params Collection of Query string, e.g. language=en
     * @param headers Collection of Header
     * @param data Payload data to post
     */
    send(path, method, params, headers, data) {
        return __awaiter(this, void 0, void 0, function* () {
            let tempBase = "http://temp";
            //tempBase will not use if the url is absolute
            let url = new URL(`${this.config.baseURL}${path}`, tempBase);
            if (params) {
                for (var key in params) {
                    url.searchParams.set(key, params[key]);
                }
            }
            const requestInit = {
                method,
                credentials: 'include',
                headers: headers !== null && headers !== void 0 ? headers : this.config.headers,
                body: data ? JSON.stringify(data) : undefined
            };
            return new Promise((resolve, reject) => {
                fetch(url.href, requestInit)
                    .then((response) => __awaiter(this, void 0, void 0, function* () {
                    if (response.ok) {
                        let json = yield response.json();
                        resolve(json);
                    }
                    else {
                        reject(response);
                    }
                }))
                    .catch((error) => {
                    reject(error);
                });
            });
        });
    }
    /**
     * Make a GET request
     *
     * @param path Api endpoint url to get
     * @param params Collection of Query string, e.g. language=en
     * @returns A promise with a T
     */
    get(path, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.send(path, "GET", params).then((response) => {
                    resolve(response);
                })
                    .catch((error) => {
                    reject(error);
                });
            });
        });
    }
    /**
     * Make a POST request
     *
     * @param path Api endpoint url to post
     * @param data Payload data to post
     * @param params Collection of Query string, e.g. language=en
     * @returns A promise with a T
     */
    post(path, data, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.send(path, "POST", params, undefined, data).then((response) => {
                    resolve(response);
                })
                    .catch((error) => {
                    reject(error);
                });
            });
        });
    }
}
