<template>
  <input type="hidden" :name="formElement.key" :value="inputValue" />
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useCurrency, useGeolocation } from "@/core/composables";
import { globals } from "@/core/globals";
import { useUser } from "@/shared/account";
import { HiddenAutoCompleteTypes } from "../enums";
import type { ExtendedFormElement } from "../types";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  formElement: ExtendedFormElement;
  product?: Product;
}

const props = defineProps<IProps>();
const inputValue = ref<string>();

onMounted(async () => {
  inputValue.value = await getValue();
});

async function getValue(): Promise<string | undefined> {
  switch (props.formElement.properties.autoCompleteValue) {
    case HiddenAutoCompleteTypes.Currency:
      return useCurrency().currentCurrency.value.code;
    case HiddenAutoCompleteTypes.CountryCode:
      return await useGeolocation(globals.optimizelyHost + "/");
    case HiddenAutoCompleteTypes.Email: {
      const { user } = useUser();
      return user.value?.email;
    }
    case HiddenAutoCompleteTypes.Name: {
      const { user } = useUser();
      return user.value?.contact?.fullName;
    }
    case HiddenAutoCompleteTypes.Url:
      return window.location.href;
    case HiddenAutoCompleteTypes.ItemNumber:
      return props.product?.code;
    default:
      return "";
  }
}
</script>
