import { useFetch } from "../api/common/composables/useFetch";
import { Logger } from "../utilities/logger";

export async function useGeolocation(hostname: string) {
  try {
    if (localStorage.getItem("countryCode")) {
      return localStorage.getItem("countryCode") || "";
    }

    const { data } = await useFetch<string>(hostname + "/api/geolocation");
    const countryCode = data.value || "";

    localStorage.setItem("countryCode", countryCode);

    return countryCode;
  } catch (error) {
    Logger.error("Failed to get country code:", error);
    throw error;
  }
}
