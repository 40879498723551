import { graphqlClient } from "../../../client";
import mutationDocument from "./addWishlistItemsMutation.graphql";
import type {
  InputAddWishlistItemsType,
  Mutations,
  MutationsAddWishlistItemsArgs,
  WishlistType,
} from "@/core/api/graphql/types";

export async function addWishlistItems(payload: InputAddWishlistItemsType): Promise<WishlistType> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "addWishlistItems">>,
    MutationsAddWishlistItemsArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: payload,
    },
  });

  return data!.addWishlistItems;
}
