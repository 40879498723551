import { graphqlClient } from "../../../client";
import mutationDocument from "./updateLanguageAndCurrency.graphql";
import type {
  InputUpdateLanguageAndCurrencyType,
  Mutations,
  MutationsUpdateLanguageAndCurrencyArgs,
} from "@/core/api/graphql/types";

export async function updateLanguageAndCurrency(
  languageAndCurrencyInput: InputUpdateLanguageAndCurrencyType,
): Promise<boolean | undefined> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "updateLanguageAndCurrency">>,
    MutationsUpdateLanguageAndCurrencyArgs
  >({
    mutation: mutationDocument,
    variables: { command: languageAndCurrencyInput },
  });

  return data!.updateLanguageAndCurrency;
}
