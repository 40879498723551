<template>
  <div
    :class="[
      'vc-checkbox',
      `vc-checkbox--size--${size}`,
      `vc-checkbox--label--${labelPosition}`,
      {
        'vc-checkbox--disabled': disabled,
        'vc-checkbox--checked': checked,
      },
    ]"
  >
    <label class="vc-checkbox__container">
      <input
        type="checkbox"
        :aria-label="name"
        :name="name"
        :value="value"
        :disabled="disabled"
        :checked="checked"
        :indeterminate="indeterminate"
        :aria-checked="checked"
        class="vc-checkbox__input"
        @change="change"
      />

      <span v-if="$slots.default" class="vc-checkbox__label">
        <slot v-bind="{ checked }" />
      </span>
    </label>

    <VcInputDetails
      class="vc-checkbox__details"
      :show-empty="showEmptyDetails"
      :message="message"
      :error="error"
      :single-line="singleLineMessage"
    />
  </div>
</template>

<script setup lang="ts">
// FIXME: https://virtocommerce.atlassian.net/browse/ST-3812
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed } from "vue";

interface IProps {
  disabled?: boolean;
  modelValue?: boolean | any[];
  name?: string;
  value?: boolean | string | number | object;
  indeterminate?: boolean;
  size?: "sm" | "md";
  labelPosition?: "left" | "right";
  showEmptyDetails?: boolean;
  message?: string;
  error?: boolean;
  singleLineMessage?: boolean;
}

const emit = defineEmits<{
  (event: "update:modelValue", value: boolean | any[]): void;
  (event: "change", value: boolean | any[]): void;
}>();

const props = withDefaults(defineProps<IProps>(), {
  modelValue: () => [],
  size: "md",
  labelPosition: "right",
});

const checked = computed<boolean>(() =>
  typeof props.modelValue === "boolean" ? props.modelValue : props.modelValue.includes(props.value),
);

function change() {
  if (props.disabled) {
    return;
  }

  if (typeof props.modelValue === "boolean") {
    const newValue = !props.modelValue;
    emit("update:modelValue", newValue);
    emit("change", newValue);
  } else {
    const newArray = [...props.modelValue];
    const index = newArray.indexOf(props.value);

    if (index === -1) {
      newArray.push(props.value);
    } else {
      newArray.splice(index, 1);
    }

    emit("update:modelValue", newArray);
    emit("change", newArray);
  }
}
</script>

<style lang="scss">
.vc-checkbox {
  $disabled: "";
  $left: "";
  $right: "";

  @apply select-none;

  &--size {
    &--sm {
      --size: 1.125rem;

      @apply text-xs;
    }

    &--md {
      --size: 1.25rem;

      @apply text-sm;
    }
  }

  &--disabled {
    $disabled: &;
  }

  &--label {
    &--left {
      $left: &;
    }

    &--right {
      $right: &;
    }
  }

  &__container {
    @apply flex items-center cursor-pointer;

    #{$disabled} & {
      @apply cursor-not-allowed;
    }
  }

  &__input {
    @apply size-[--size] shrink-0 cursor-pointer appearance-none rounded border border-[--color-neutral-a4] bg-additional-50;

    &:checked {
      @apply border-none bg-[--color-link] bg-no-repeat bg-center bg-[length:12px];

      background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Vector' d='M10.0915 0.451972L10.0867 0.446075L10.0813 0.440568C9.90076 0.253564 9.61034 0.253146 9.42927 0.439309L4.16201 5.72962L1.58507 3.13469C1.40401 2.94841 1.11351 2.94879 0.932892 3.13584C0.755703 3.31933 0.755703 3.60875 0.932892 3.79224L0.932878 3.79225L0.934851 3.79424L3.58046 6.45832C3.73676 6.61955 3.94983 6.7 4.1473 6.7C4.36196 6.7 4.55963 6.61773 4.71406 6.4584L10.0468 1.10234C10.2436 0.919903 10.2421 0.633904 10.0915 0.451972ZM4.2327 5.80081L4.2317 5.7998C4.23206 5.80015 4.23237 5.80049 4.23269 5.80082L4.2327 5.80081Z' fill='white' stroke='white' stroke-width='0.4'/%3E%3C/svg%3E%0A");
    }

    &:indeterminate {
      @apply border-none bg-neutral bg-no-repeat bg-center bg-contain;

      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4 10a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v0a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 4 10z' fill='white'/%3e%3c/svg%3e");
    }

    &:disabled {
      @apply bg-neutral-50 cursor-not-allowed;

      &:checked,
      &:indeterminate {
        @apply border-neutral-200 bg-neutral-200;
      }
    }
  }

  &__label {
    @apply min-w-0;

    #{$left} & {
      @apply order-first me-2;
    }

    #{$right} & {
      @apply order-last ms-2;
    }

    #{$disabled} & {
      @apply opacity-60;
    }
  }

  &__details {
    @apply min-w-full;
  }
}
</style>
