import { graphqlClient } from "../../../client";
import queryDocument from "./getProductNotificationQuery.graphql";
import type { Query, ProductNotificationType, QueryProductNotificationArgs } from "@/core/api/graphql/types";

export async function getProductNotification(codes: string[]): Promise<ProductNotificationType[]> {
  const { data } = await graphqlClient.query<
    Required<Pick<Query, "productNotification">>,
    QueryProductNotificationArgs
  >({
    query: queryDocument,
    variables: {
      codes,
    },
  });

  return data.productNotification;
}
