<template>
  <VcSelect
    v-model="model"
    :name="formElement.key"
    text-field="caption"
    value-field="value"
    :items="formElement.properties.items"
    :label="formElement.properties.label"
    :placeholder="formElement.properties.placeHolder"
    class="w-full"
    :required="isRequired"
    :disabled="!formElement.properties.items.length"
    :message="message"
    :error="error"
    size="auto"
    autocomplete
    @change="validate"
  />
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from "vue";
import { FormValidator } from "../forms-sdk";
import type { ElementValidationResult } from "../forms-sdk";
import type { ExtendedFormElement } from "../types";

interface IProps {
  formElement: ExtendedFormElement;
  errorMessage?: ElementValidationResult;
}

interface IEmits {
  (event: "update:error-message", error: ElementValidationResult): void;
}
const emit = defineEmits<IEmits>();

const props = defineProps<IProps>();
const model = defineModel<string>();
const elementValidationResult = ref<ElementValidationResult>({ valid: true, message: "" });
const message = computed(() => elementValidationResult.value?.message || props.errorMessage?.message);
const error = computed(() => !!elementValidationResult.value?.message || !!props.errorMessage?.message);

onBeforeMount(() => {
  model.value = props.formElement.properties.items.find((item) => item.checked)?.value;
});

const isRequired = computed(
  () => !!props.formElement.properties.validators.find((v) => v.type === "RequiredValidator"),
);

const formValidator = new FormValidator(props.formElement);

function validate(): void {
  elementValidationResult.value = formValidator.validate(model.value);
  emit("update:error-message", elementValidationResult.value);
}
</script>
