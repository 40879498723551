<!-- eslint-disable prettier/prettier -->
<template>
  <div
class="grid grid-cols-1 content-start gap-y-2 "
  :class="{'md:min-h-[70px]': currencyCode != 'GBP',}"
  >
    <div
      v-if="hoursLine1 || hoursLine2"
      class="text-sm font-bold text-[color:var(--color-footer-top-text)]"
    >
      {{ $t("shared.layout.footer.hours") }}
    </div>
    <div class="flex flex-wrap justify-center gap-x-4 md:grid md:justify-start md:gap-x-0 md:gap-y-2">
      <div v-if="hoursLine1" class="text-sm font-medium text-[color:var(--color-footer-top-text)]">
        {{ hoursLine1 }}
      </div>
      <div v-if="hoursLine2" class="text-sm font-medium text-[color:var(--color-footer-top-text)]">
        {{ hoursLine2 }}
      </div>
      <div v-if="currencyCode === 'CNY'" class="text-sm font-medium text-[color:var(--color-footer-top-text)]">&nbsp;</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { useCurrency } from "@/core/composables";
defineProps<Props>();

const { currentCurrency } = useCurrency();
const currencyCode = currentCurrency.value.code;

interface Props {
  hoursLine1?: string;
  hoursLine2?: string;
}
</script>
