<template>
  <VcInput
    v-model="inputValue"
    :for="formElement.key"
    :label="getLabel()"
    :name="formElement.key"
    :placeholder="formElement.properties.placeHolder"
    :type="getType()"
    :required="isRequired"
    autocomplete="off"
    :message="message"
    :error="error"
    @input="validate"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useUser } from "@/shared/account";
import { FormElementsContentTypes, LabelPlaceholders, TextAutoCompleteTypes } from "../enums";
import { FormValidator } from "../forms-sdk";
import type { ElementValidationResult } from "../forms-sdk";
import type { ExtendedFormElement } from "../types";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  formElement: ExtendedFormElement;
  product?: Product;
  errorMessage?: ElementValidationResult;
}

interface IEmits {
  (event: "update:error-message", error: ElementValidationResult): void;
}
const emit = defineEmits<IEmits>();

const props = defineProps<IProps>();
const inputValue = ref<string>(getValue() ?? "");
const elementValidationResult = ref<ElementValidationResult>({ valid: true, message: "" });
const message = computed(() => elementValidationResult.value?.message || props.errorMessage?.message);
const error = computed(() => !!elementValidationResult.value?.message || !!props.errorMessage?.message);

function getLabel(): string {
  let label = props.formElement.properties.label;
  const regex = /{{(.*)}}/;

  if (label) {
    regex.exec(label)?.forEach((placeholder) => {
      switch (placeholder) {
        case LabelPlaceholders.ItemNumber:
          label = label.replace(regex, props.product?.code ?? "");
          break;
        case "":
          break;
        default:
      }
    });
  }

  return label;
}

function getType(): "number" | "text" {
  return props.formElement.contentType === FormElementsContentTypes.NumberElementBlock ? "number" : "text";
}

const isRequired = computed(
  () => !!props.formElement.properties.validators.find((v) => v.type === "RequiredValidator"),
);

function getValue(): string | undefined {
  switch (props.formElement.properties.autoComplete) {
    case TextAutoCompleteTypes.Email: {
      const { user } = useUser();
      return user.value?.email;
    }
    case TextAutoCompleteTypes.Name: {
      const { displayName } = useUser();
      return displayName.value;
    }
    default:
      return "";
  }
}

const formValidator = new FormValidator(props.formElement);

function validate(event: Event): void {
  const input = event.target as HTMLInputElement;
  elementValidationResult.value = formValidator.validate(input.value);
  emit("update:error-message", elementValidationResult.value);
}
</script>
