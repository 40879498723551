/**
 * enum represent a dependent condition Operator (in the Rule to evaluate depend value).
 */
export var ConditionFunctionType;
(function (ConditionFunctionType) {
    ConditionFunctionType["MatchRegularExpression"] = "MatchRegularExpression";
    ConditionFunctionType["Contains"] = "Contains";
    ConditionFunctionType["NotContains"] = "NotContains";
    ConditionFunctionType["Equals"] = "Equals";
    ConditionFunctionType["NotEquals"] = "NotEquals";
})(ConditionFunctionType || (ConditionFunctionType = {}));
