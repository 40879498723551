import { graphqlClient } from "../../../client";
import queryDocument from "./validateCouponQuery.graphql";
import type { Query, QueryValidateCouponArgs } from "@/core/api/graphql/types";

// export function useValidateCouponMutation(cart?: MaybeRef<CartIdFragment | undefined>) {
//     return useMutation(ValidateCouponDocument, useCartMutationVariables(cart));
// }

/** @deprecated Use {@link useValidateCouponMutation} instead. */
export async function useValidateCoupon(
  cartId: string,
  storeId: string,
  currencyCode: string,
  userId: string,
  cultureName: string,
  cartName: string,
  cartType: string,
  coupon: string,
): Promise<boolean> {
  const { data } = await graphqlClient.query<Required<Pick<Query, "validateCoupon">>, QueryValidateCouponArgs>({
    query: queryDocument,
    variables: {
      cartId,
      storeId,
      currencyCode,
      userId,
      cultureName,
      cartName,
      cartType,
      coupon,
    },
  });

  return data!.validateCoupon!;
}
