import { graphqlClient } from "../../../client";
import mutationDocument from "./updateMemberAddressesMutationExtension.graphql";
import type {
  InputUpdateMemberAddressTypeExtension,
  Mutations,
  MutationsUpdateMemberAddressesExtensionArgs,
} from "@/core/api/graphql/types";

export async function updateMemberAddresses(
  memberId: string,
  addresses: InputUpdateMemberAddressTypeExtension[],
): Promise<void> {
  await graphqlClient.mutate<
    Required<Pick<Mutations, "updateMemberAddressesExtension">>,
    MutationsUpdateMemberAddressesExtensionArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: {
        memberId,
        addresses,
      },
    },
  });
}
