/**
 * The list of validator type
 */
export var ValidatorType;
(function (ValidatorType) {
    ValidatorType["RequiredValidator"] = "RequiredValidator";
    ValidatorType["EmailValidator"] = "EmailValidator";
    ValidatorType["RegularExpressionValidator"] = "RegularExpressionValidator";
    ValidatorType["DateDDMMYYYYValidator"] = "DateDDMMYYYYValidator";
    ValidatorType["DateMMDDYYYYValidator"] = "DateMMDDYYYYValidator";
    ValidatorType["DateYYYYMMDDValidator"] = "DateYYYYMMDDValidator";
    ValidatorType["UrlValidator"] = "UrlValidator";
    ValidatorType["IntegerValidator"] = "IntegerValidator";
    ValidatorType["PositiveIntegerValidator"] = "PositiveIntegerValidator";
    ValidatorType["AllowedExtensionsValidator"] = "AllowedExtensionsValidator";
    ValidatorType["MaxFileSizeValidator"] = "MaxFileSizeValidator";
    ValidatorType["NumericValidator"] = "NumericValidator";
    ValidatorType["CaptchaValidator"] = "CaptchaValidator";
})(ValidatorType || (ValidatorType = {}));
