import { graphqlClient } from "../../../client";
import mutationDocument from "./updateUsername.graphql";
import type {
  IdentityResultType,
  InputUpdateUsernameType,
  Mutations,
  MutationsUpdateUsernameArgs,
} from "@/core/api/graphql/types";

export async function updateUsername(usernameInput: InputUpdateUsernameType): Promise<IdentityResultType> {
  const { data } = await graphqlClient.mutate<Required<Pick<Mutations, "updateUsername">>, MutationsUpdateUsernameArgs>(
    {
      mutation: mutationDocument,
      variables: { command: usernameInput },
    },
  );

  return data!.updateUsername;
}
