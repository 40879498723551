export const eligibleVatCountries: string[] = [
  "USA", // United States
  "AUT", // Austria
  "BEL", // Belgium
  "BGR", // Bulgaria
  "CYP", // Cyprus
  "CZE", // Czech Republic
  "DEU", // Germany
  "DNK", // Denmark
  "EST", // Estonia
  "GRC", // Greece
  "ESP", // Spain
  "FIN", // Finland
  "FRA", // France
  "HRV", // Croatia
  "HUN", // Hungary
  "IRL", // Ireland
  "ITA", // Italy
  "LTU", // Lithuania
  "LUX", // Luxembourg
  "LVA", // Latvia
  "MLT", // Malta
  "NLD", // Netherlands
  "POL", // Poland
  "PRT", // Portugal
  "ROU", // Romania
  "SWE", // Sweden
  "SVN", // Slovenia
  "SVK", // Slovakia
];
