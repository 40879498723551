<template>
  <div
    v-if="isVisible"
    class="alert-banner relative z-20 flex border-b py-4 pl-4 pr-10 text-base font-medium md:px-14 md:text-sm"
    :style="{
      backgroundColor: colorCodes[0],
      borderColor: colorCodes[1],
      color: 'var(--color-neutral-a1)',
    }"
  >
    <div class="mx-auto flex items-center justify-center gap-2">
      <VcImage
        v-if="block.icon?.url"
        :src="block.icon.url"
        class="mt-[3px] flex size-4 shrink-0 self-start md:size-3"
        alt=""
        role="presentation"
      />
      <div v-html="block.text"></div>
    </div>
    <button type="button" class="absolute right-4 top-4 md:right-8" aria-label="Close banner" @click="closeBanner">
      <VcIcon name="cross-circle" class="size-4" alt="" role="presentation" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import type { IBannerBlock } from "@/shared/thorlabs/blocks";

interface IProps {
  block: IBannerBlock;
}
const props = defineProps<IProps>();

const isVisible = ref(true);

const checkVisibility = () => {
  const hiddenBannerIds = getHiddenBannerIds();
  const lastUpdated = getBannerLastUpdated();
  if (hiddenBannerIds.includes(props.block.contentLink.guidValue)) {
    if (new Date(props.block.saved) > new Date(lastUpdated)) {
      isVisible.value = true;
      setBannerLastUpdated(props.block.saved);
      removeHiddenBannerId(props.block.contentLink.guidValue);
    } else {
      isVisible.value = false;
    }
  } else {
    setBannerLastUpdated(props.block.saved);
    isVisible.value = true;
  }
};

const closeBanner = () => {
  isVisible.value = false;
  addHiddenBannerId(props.block.contentLink.guidValue);
};

function splitColorCodes(colorString: string) {
  return colorString.split(";");
}

const colorCodes = computed(() => splitColorCodes(props.block.color));

onMounted(() => {
  checkVisibility();
});

function getHiddenBannerIds(): string[] {
  const hiddenBannerIds = localStorage.getItem("hiddenBannerIds");
  return hiddenBannerIds ? JSON.parse(hiddenBannerIds) : [];
}

function addHiddenBannerId(id: string) {
  const hiddenBannerIds = getHiddenBannerIds();
  if (!hiddenBannerIds.includes(id)) {
    hiddenBannerIds.push(id);
    localStorage.setItem("hiddenBannerIds", JSON.stringify(hiddenBannerIds));
  }
}

function removeHiddenBannerId(id: string) {
  let hiddenBannerIds = getHiddenBannerIds();
  hiddenBannerIds = hiddenBannerIds.filter((bannerId) => bannerId !== id);
  localStorage.setItem("hiddenBannerIds", JSON.stringify(hiddenBannerIds));
}

function setBannerLastUpdated(date: string) {
  localStorage.setItem("bannerLastUpdated", date);
}

function getBannerLastUpdated(): string {
  return localStorage.getItem("bannerLastUpdated") || "";
}
defineExpose({ isVisible });
</script>

<style scoped>
.main-wrapper .alert-banner:first-of-type {
  @apply z-50;
}
</style>
