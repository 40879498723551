<template>
  <VcInput
    v-model="dateOnly"
    :label="label"
    :disabled="isDisabled"
    :name="name"
    type="date"
    required
    :error="!!errorMessage"
    :message="errorMessage"
  />
</template>

<script setup lang="ts">
import { useVModel } from "@vueuse/core";

interface IEmits {
  (e: "update:modelValue", value: string | undefined): void;
}

interface IProps {
  label?: string;
  name?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  modelValue?: string;
  errorMessage?: string;
}

const emit = defineEmits<IEmits>();
const props = defineProps<IProps>();

const dateOnly = useVModel(props, "modelValue", emit);
</script>
