import { useLazyQuery } from "@vue/apollo-composable";
import { useCartQueryVariables } from "@/core/api/graphql/cart/composables";
import { GetFullCartDocument } from "@/core/api/graphql/types";
import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import getFullCartQueryDocument from "./getFullCartQuery.graphql";
import type { CartExtension, Query, QueryCartArgs } from "@/core/api/graphql/types";

export function useGetFullCartQuery() {
  return useLazyQuery(GetFullCartDocument, useCartQueryVariables(), {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    keepPreviousResult: true,
  });
}

export async function getFullCartById(cartId: string): Promise<CartExtension> {
  const { storeId, cultureName, currencyCode, userId } = globals;
  const payload = <QueryCartArgs>{
    storeId,
    cultureName,
    currencyCode,
    userId,
    cartId,
  };
  const { data } = await graphqlClient.query<Required<Pick<Query, "cart">>, QueryCartArgs>({
    query: getFullCartQueryDocument,
    variables: {
      ...payload,
    },
  });

  return data.cart;
}
