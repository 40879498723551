import { graphqlClient } from "../../../client";
import validateMelissaAddressesQuery from "./validateAddress.graphql";
import type { AddressValidationResponseType, QueryValidateMelissaAddressesArgs, Query } from "@/core/api/graphql/types";

export async function validateAddress(
  payload?: QueryValidateMelissaAddressesArgs,
): Promise<AddressValidationResponseType> {
  const { data } = await graphqlClient.query<
    Required<Pick<Query, "validateMelissaAddresses">>,
    QueryValidateMelissaAddressesArgs
  >({
    query: validateMelissaAddressesQuery,
    variables: {
      ...payload,
    },
  });

  return data.validateMelissaAddresses || null;
}
