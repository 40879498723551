<template>
  <div
    v-if="!model.hidden && product.variations?.length"
    id="variations-container"
    class="variations my-8 overflow-hidden rounded-md border border-solid border-[--color-neutral-a4] md:mb-0"
  >
    <div class="font-medium leading-6">
      <div class="bg-[--color-neutral-a6] px-5 py-[14px]">
        <h3 class="m-0 text-base font-bold text-[--color-neutral-a05]">
          {{ model.title || $t("shared.catalog.product_details.product_variations.choose_unit") }}
        </h3>
      </div>
      <VariationsDefault v-if="isSmallScreen || (!isSmallScreen && !isTableView)" :product="product" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from "@vueuse/core";
import { ref } from "vue";
import { BREAKPOINTS } from "@/core/constants";
import VariationsDefault from "./variations-default.vue";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  product: Product;
  model: {
    title?: string;
    hidden?: boolean;
  };
}

defineProps<IProps>();

const breakpoints = useBreakpoints(BREAKPOINTS);

const isTableView = ref(false);
const isSmallScreen = breakpoints.smaller("xl");
</script>

<style lang="scss">
.variations {
  &__views {
    @apply mb-6 space-x-3;
  }

  &__view {
    @apply p-2 rounded bg-transparent text-sm text-neutral font-bold;

    &:hover {
      @apply bg-neutral-50;
    }

    &:disabled {
      @apply bg-neutral-100 text-neutral-950;
    }
  }

  &__icon {
    @apply me-1 size-5;

    *:disabled > & {
      @apply text-primary;
    }
  }
}
</style>
