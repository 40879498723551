import { useEditMode } from "@/shared/thorlabs/shared/composables";
import type { Directive, App, Plugin, DirectiveBinding } from "vue";

function toggleEditAttributes(el: HTMLElement, binding: DirectiveBinding<string>) {
  const siteIsEditable = useEditMode().isEditable.value;

  if (siteIsEditable) {
    el.setAttribute("data-epi-edit", binding.value);
  } else {
    el.removeAttribute("data-epi-edit");
  }
}

const EpiEdit: Directive<HTMLElement, string> = {
  mounted(el, binding) {
    toggleEditAttributes(el, binding);
  },
  updated(el, binding) {
    toggleEditAttributes(el, binding);
  },
};

export const optiPlugin: Plugin = {
  install(app: App) {
    app.directive("epi-edit", EpiEdit);
  },
};
