var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FormStorage } from "../form-storage";
import { FormValidator } from "../form-validator";
import { equals, isNull } from "../helpers";
import { ApiConstant } from "../form-loader/apiConstant";
/**
 * Class to submit form submission to Headless Form API
 */
export class FormSubmitter {
    constructor(form, baseUrl) {
        this._form = form;
        this._baseUrl = baseUrl;
    }
    /**
     * Combine 2 data from storage and form submission
     * @param dataFromStorage the array of form data from session storage
     * @param submissionData the array of form submission
     */
    combineData(dataFromStorage, submissionData) {
        const mapFromArray = new Map();
        submissionData.forEach(element => {
            mapFromArray.set(element.elementKey, element);
        });
        const combinedData = [...submissionData, ...dataFromStorage.filter(element => !mapFromArray.has(element.elementKey))];
        return combinedData;
    }
    /**
     * Post an array of form submission to the Headless Form API
     * @param formSubmission the array of form submission to post
     */
    doSubmit(model) {
        return new Promise((resolve, reject) => {
            // Post data to API
            let formData = new FormData();
            let formSubmissionData = {};
            formSubmissionData.FormKey = model.formKey;
            formSubmissionData.Locale = model.locale;
            formSubmissionData.IsFinalized = model.isFinalized;
            formSubmissionData.SubmissionKey = model.partialSubmissionKey;
            formSubmissionData.HostedPageUrl = model.hostedPageUrl;
            formSubmissionData.CurrentStep = model.currentStepIndex;
            let fieldsData = {};
            //append form submission to FormData object
            model.submissionData.forEach(data => {
                let ovalue = data.value;
                let key = data.elementKey;
                if (isNull(ovalue)) {
                    return;
                }
                // checking file upload elements, item must be File if any,
                // for using Object.getPrototypeOf(variable) variable must be object type
                if (Array.isArray(ovalue) && ovalue.length > 0 &&
                    ovalue[0] !== null && typeof ovalue[0] === "object") {
                    let files = ovalue, fileNames = "";
                    // append each upload file with a unique key (bases on element's key) so that the server side can see it through the Request.Files,
                    // concat all the files' name and assign with the element's Id
                    for (var idx = 0; idx < files.length; idx++) {
                        let ofile = files[idx].file;
                        if (ofile && Object.getPrototypeOf(ofile) === File.prototype) {
                            formData.append(key + "_file_" + idx, ofile);
                        }
                        // always send file name to server if existed to handle case upload file then click back
                        // charactor | cannot be used in filename and then is safe for splitting later
                        if (idx > 0 && idx != files.length - 1) {
                            fileNames += " | ";
                        }
                        fileNames += files[idx].name;
                    }
                    formData.append(key, fileNames);
                    data.prevValue = fileNames;
                }
                else {
                    fieldsData[key] = data.value;
                }
            });
            formSubmissionData.Fields = fieldsData;
            formData.append("data", JSON.stringify(formSubmissionData));
            // Save data to session storage
            let formStorage = new FormStorage(this._form);
            let currentData = formStorage.loadFormDataFromStorage();
            let dataCombined = this.combineData(currentData, model.submissionData);
            formStorage.saveFormDataToStorage(dataCombined);
            //init a request and call ajax
            let requestInit = {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${model.accessToken}`,
                },
                body: formData
            };
            fetch(`${this._baseUrl}${ApiConstant.apiEndpoint}`, requestInit)
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                let json = yield response.json();
                if (response.ok) {
                    let result = json;
                    if (result.success && model.isFinalized) {
                        //clear cache of form submission
                        formStorage.removeFormDataInStorage();
                    }
                    resolve(result);
                }
                else {
                    reject(json);
                }
            }))
                .catch((error) => {
                reject(error);
            });
        });
    }
    /**
     * Function to validate data before submit
     * @param formSubmission the array of form submission to post
     * @returns An array of validation result
     */
    doValidate(formSubmissions) {
        return this._form.formElements
            .filter(e => formSubmissions.some(fs => equals(fs.elementKey, e.key)))
            .map(e => {
            var _a;
            let formValidator = new FormValidator(e);
            let value = (_a = formSubmissions.filter(fs => equals(fs.elementKey, e.key))[0]) === null || _a === void 0 ? void 0 : _a.value;
            return {
                elementKey: e.key,
                result: formValidator.validate(value)
            };
        });
    }
}
