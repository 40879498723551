<template>
  <form class="overflow-x-hidden px-5 pb-8 pt-5" @submit.prevent="save">
    <slot name="prepend" v-bind="slotsData" />

    <div v-if="isJapan" :class="{ 'flex flex-col': withPersonalInfo }">
      <div v-if="withPersonalInfo" class="w-full">
        <div class="flex justify-between gap-5">
          <VcInput
            v-model="lastName"
            :message="errors.lastName"
            :error="!!errors.lastName"
            :disabled="disabled"
            :label="$t('common.labels.last_name')"
            class="mb-4 w-full"
            :placeholder="$t('common.placeholders.last_name')"
            required
            :maxlength="64"
          />
          <VcInput
            v-model="firstName"
            :message="errors.firstName"
            :error="!!errors.firstName"
            :disabled="disabled"
            :label="$t('common.labels.first_name')"
            class="mb-4 w-full"
            :placeholder="$t('common.placeholders.first_name')"
            required
            :maxlength="64"
          />
        </div>

        <VcInput
          v-model="organization"
          :disabled="disabled"
          :message="errors.organization"
          :error="!!errors.organization"
          :label="$t('common.labels.company_name')"
          :placeholder="$t('common.placeholders.company_name')"
          class="mb-4"
          :maxlength="128"
          required
        />

        <VcInput
          v-model="departmentTL"
          :placeholder="$t('common.placeholders.department')"
          :label="$t('common.labels.department')"
          class="mb-4 w-full"
        />

        <VcSelect
          v-model="country"
          text-field="name"
          :message="errors.countryCode"
          :error="!!errors.countryCode"
          :disabled="disabled"
          :items="countries"
          :label="$t('common.labels.country')"
          :placeholder="$t('common.placeholders.select_country')"
          class="mb-4 w-full"
          required
          autocomplete
        />

        <VcInput
          v-model="postalCode"
          :message="errors.postalCode"
          :error="!!errors.postalCode"
          :disabled="disabled"
          :label="$t(`${countryConfig?.zipLabel!}`)"
          :placeholder="$t('common.placeholders.postal_code_jp')"
          class="mb-4 w-full"
          required
          :maxlength="32"
          @input="formatPostalCode"
        />

        <VcSelect
          v-if="countryConfig?.stateLabel && regions.length > 1"
          v-model="region"
          text-field="name"
          :items="regions"
          :message="errors.regionId"
          :error="!!errors.regionId"
          :required="!!regions.length"
          :disabled="disabled || !regions.length"
          :label="$t(`${countryConfig?.stateLabel!}`)"
          :placeholder="$t('common.placeholders.select_region')"
          class="mb-4 w-full"
          autocomplete
        />
        <VcInput
          v-if="countryConfig?.stateLabel && regions.length < 2"
          v-model="regionName"
          :message="errors.regionId"
          :error="!!errors.regionId"
          :placeholder="$t('common.placeholders.prefecture')"
          :disabled="disabled"
          :label="$t(`${countryConfig?.stateLabel!}`)"
          class="mb-4 w-full"
          :maxlength="128"
        />

        <div class="flex gap-5">
          <VcInput
            v-if="countryConfig?.cityLabel"
            v-model="city"
            :message="errors.city"
            :error="!!errors.city"
            :disabled="disabled"
            :label="$t(`${countryConfig?.cityLabel!}`)"
            :placeholder="$t('common.placeholders.city_jp')"
            class="mb-4 w-full"
            :required="requiredCity"
            :maxlength="128"
          />

          <VcSelect
            v-if="cities.length > 1"
            v-model="selectedCity"
            :label="$t('common.labels.select_one')"
            text-field="name"
            :items="cities"
            class="w-full"
            :disabled="disabled"
            autocomplete
          />
        </div>

        <VcInput
          v-model="line1"
          :message="errors.line1"
          :error="!!errors.line1"
          :disabled="disabled"
          :label="$t(`${countryConfig?.addressLabel!}`)"
          :placeholder="$t('common.placeholders.address_line1')"
          class="mb-4"
          required
          :maxlength="128"
        />

        <VcInput
          v-model="buildingTL"
          :placeholder="$t('common.placeholders.building')"
          :label="$t('common.labels.building')"
          class="mb-4 mt-5 w-full"
        />

        <div class="mb-5 w-full">
          <div>
            <button type="button" class="faq-btn flex w-full text-left" @click="handleToggleAddressLine2">
              <div class="flex items-center gap-1 font-medium text-[--color-neutral-a1] underline">
                <VcIcon :name="addressLine2FormOpen ? 'minus' : 'plus-alt-sm'" :size="7"></VcIcon>
                {{ $t("common.labels.address_line2_jp") }}
              </div>
            </button>

            <div v-show="addressLine2FormOpen" class="mt-5">
              <VcInput
                v-model="mailStopTL"
                :placeholder="$t('common.placeholders.mailstop')"
                :label="$t('common.labels.mailstop')"
                class="mt-5 w-full"
              />
            </div>
          </div>
        </div>

        <VcInput
          v-model="phone"
          :message="errors.phone"
          :error="!!errors.phone"
          :disabled="disabled"
          :required="requiredPhone"
          :label="$t('common.labels.phone')"
          :placeholder="$t('common.placeholders.phone')"
          class="mb-4"
          :maxlength="64"
        />

        <VcInput
          v-model="email"
          :message="errors.email"
          :error="!!errors.email"
          :disabled="disabled"
          :required="requiredEmail"
          :label="$t('common.labels.email')"
          :placeholder="$t('common.placeholders.email')"
          class="mb-4"
          :maxlength="64"
        />

        <div class="flex flex-col gap-5 pb-5 text-base font-medium leading-6 text-[--color-neutral-a1]">
          <VcCheckbox v-model="isDefaultBillingAddressTL">{{ $t("common.labels.default_billing_address") }}</VcCheckbox>
          <VcCheckbox v-model="isDefaultShippingAddressTL"
            >{{ $t("common.labels.default_shipping_address") }}
          </VcCheckbox>
        </div>
      </div>
    </div>
    <div v-else :class="{ 'flex flex-col': withPersonalInfo }">
      <div v-if="withPersonalInfo" class="w-full">
        <div class="flex justify-between gap-5">
          <template v-if="isChina">
            <VcInput
              v-model="lastName"
              :message="errors.lastName"
              :error="!!errors.lastName"
              :disabled="disabled"
              :label="$t('common.labels.last_name')"
              class="mb-4 w-full"
              :placeholder="$t('common.placeholders.last_name')"
              required
              :maxlength="64"
            />
            <VcInput
              v-model="firstName"
              :message="errors.firstName"
              :error="!!errors.firstName"
              :disabled="disabled"
              :label="$t('common.labels.first_name')"
              class="mb-4 w-full"
              :placeholder="$t('common.placeholders.first_name')"
              required
              :maxlength="64"
            />
          </template>
          <template v-else>
            <VcInput
              v-model="firstName"
              :message="errors.firstName"
              :error="!!errors.firstName"
              :disabled="disabled"
              :label="$t('common.labels.first_name')"
              class="mb-4 w-full"
              :placeholder="$t('common.placeholders.first_name')"
              required
              :maxlength="64"
            />
            <VcInput
              v-model="lastName"
              :message="errors.lastName"
              :error="!!errors.lastName"
              :disabled="disabled"
              :label="$t('common.labels.last_name')"
              class="mb-4 w-full"
              :placeholder="$t('common.placeholders.last_name')"
              required
              :maxlength="64"
            />
          </template>
        </div>

        <VcInput
          v-model="organization"
          :disabled="disabled"
          :label="$t('common.labels.company_name_optional')"
          :placeholder="$t('common.placeholders.company_name')"
          class="mb-4"
          :maxlength="128"
        />

        <VcSelect
          v-model="country"
          text-field="name"
          :message="errors.countryCode"
          :error="!!errors.countryCode"
          :disabled="disabled"
          :items="countries"
          :label="$t('common.labels.country')"
          :placeholder="$t('common.placeholders.select_country')"
          class="mb-4 w-full"
          required
          autocomplete
        />

        <VcInput
          v-model="line1"
          :message="errors.line1"
          :error="!!errors.line1"
          :disabled="disabled"
          :label="$t(`${countryConfig?.addressLabel!}`)"
          :placeholder="$t(`${countryConfig?.addressLabel!.replace('labels', 'placeholders')}`)"
          class="mb-4"
          required
          :maxlength="128"
        />

        <div class="mb-5 w-full">
          <div>
            <button type="button" class="faq-btn flex w-full text-left" @click="handleToggleAddressLine2">
              <div class="flex items-center gap-1 font-medium text-[--color-neutral-a1] underline">
                <VcIcon :name="addressLine2FormOpen ? 'minus' : 'plus-alt-sm'" :size="7"></VcIcon>
                {{ $t("common.labels.address_line2") }}
              </div>
            </button>

            <div v-show="addressLine2FormOpen" class="mt-5">
              <VcInput
                v-model="departmentTL"
                :placeholder="$t('common.placeholders.department')"
                :label="$t('common.labels.department')"
                class="w-full"
              />
              <VcInput
                v-model="buildingTL"
                :placeholder="$t('common.placeholders.building')"
                :label="$t('common.labels.building')"
                class="mt-5 w-full"
              />
              <VcInput
                v-model="mailStopTL"
                :placeholder="$t('common.placeholders.mailstop')"
                :label="$t('common.labels.mailstop')"
                class="mt-5 w-full"
              />
            </div>
          </div>
        </div>

        <div class="flex gap-5">
          <VcInput
            v-if="countryConfig?.districtLabel"
            v-model="districtTL"
            :disabled="disabled"
            :label="$t(`${countryConfig?.districtLabel!}`)"
            :placeholder="$t(`${countryConfig?.districtLabel!.replace('labels', 'placeholders')}`)"
            class="mb-4 w-full"
            required
            :maxlength="128"
          />

          <VcSelect
            v-if="districts.length > 1"
            v-model="selectedDistrict"
            :label="$t('common.labels.select_one')"
            text-field="name"
            :items="districts"
            class="w-full"
            :disabled="disabled"
            autocomplete
          />
        </div>

        <VcInput
          v-model="postalCode"
          :message="errors.postalCode"
          :error="!!errors.postalCode"
          :disabled="disabled"
          :label="$t(`${countryConfig?.zipLabel!}`)"
          :placeholder="$t(`${countryConfig?.zipLabel!.replace('labels', 'placeholders')}`)"
          class="mb-4 w-full"
          required
          :maxlength="32"
        />

        <div class="flex gap-5">
          <VcInput
            v-if="countryConfig?.cityLabel"
            v-model="city"
            :message="errors.city"
            :error="!!errors.city"
            :disabled="disabled"
            :label="$t(`${countryConfig?.cityLabel!}`)"
            :placeholder="$t(`${countryConfig?.cityLabel!.replace('labels', 'placeholders')}`)"
            class="mb-4 w-full"
            :required="requiredCity"
            :maxlength="128"
          />

          <VcSelect
            v-if="cities.length > 1"
            v-model="selectedCity"
            :label="$t('common.labels.select_one')"
            text-field="name"
            :items="cities"
            class="w-full"
            :disabled="disabled"
            autocomplete
          />
        </div>

        <VcSelect
          v-if="countryConfig?.stateLabel && regions.length > 1"
          v-model="region"
          text-field="name"
          :items="regions"
          :message="errors.regionId"
          :error="!!errors.regionId"
          :required="!!regions.length"
          :disabled="disabled || !regions.length"
          :label="$t(`${countryConfig?.stateLabel!}`)"
          :placeholder="$t('common.placeholders.select_region')"
          class="mb-4 w-full"
          autocomplete
        />
        <VcInput
          v-if="countryConfig?.stateLabel && regions.length < 2"
          v-model="regionName"
          :message="errors.regionId"
          :error="!!errors.regionId"
          :disabled="disabled"
          :label="$t(`${countryConfig?.stateLabel!}`)"
          :placeholder="$t(`${countryConfig?.stateLabel!.replace('labels', 'placeholders')}`)"
          class="mb-4 w-full"
          :maxlength="128"
          required
        />

        <VcInput
          v-if="countryConfig?.countyLabel"
          v-model="countyTL"
          :message="errors.regionId"
          :error="!!errors.regionId"
          :disabled="disabled"
          :label="$t(`${countryConfig?.countyLabel!}`)"
          :placeholder="$t(`${countryConfig?.countyLabel!.replace('labels', 'placeholders')}`)"
          class="mb-4 w-full"
          :maxlength="128"
        />

        <VcInput
          v-model="phone"
          :message="errors.phone"
          :error="!!errors.phone"
          :disabled="disabled"
          :required="requiredPhone"
          :label="$t('common.labels.phone')"
          :placeholder="$t('common.placeholders.phone')"
          class="mb-4"
          :maxlength="64"
        />

        <VcInput
          v-model="email"
          :message="errors.email"
          :error="!!errors.email"
          :disabled="disabled"
          :required="requiredEmail"
          :label="$t('common.labels.email')"
          :placeholder="$t('common.placeholders.email')"
          class="mb-4"
          :maxlength="64"
        />

        <div class="flex flex-col gap-5 pb-5 text-base font-medium leading-6 text-[--color-neutral-a1]">
          <VcCheckbox v-model="isDefaultBillingAddressTL">{{ $t("common.labels.default_billing_address") }}</VcCheckbox>
          <VcCheckbox v-model="isDefaultShippingAddressTL"
            >{{ $t("common.labels.default_shipping_address") }}
          </VcCheckbox>
        </div>

        <div v-if="shouldTaxExemptNoBeDisplayed" class="mb-5 w-full">
          <div>
            <button type="button" class="faq-btn flex w-full text-left" @click="handleToggleTaxExemptNo">
              <div class="flex items-center gap-1 font-medium text-[--color-neutral-a1] underline">
                <VcIcon :name="taxExemptNoFormOpen ? 'minus' : 'plus-alt-sm'" :size="7"></VcIcon>
                {{ $t("common.labels.tax_exempt_no") }}
              </div>
            </button>

            <div v-show="taxExemptNoFormOpen">
              <VcInput
                v-model="taxExemptNoTL"
                :placeholder="$t('common.placeholders.tax_exempt_no')"
                class="mt-3 w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="append" v-bind="slotsData" />
  </form>
</template>

<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/yup";
import { useLocalStorage } from "@vueuse/core";
import { useField, useForm } from "vee-validate";
import { computed, watch, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { countryConfigsMap } from "@/core/constants/address-country-configs";
import { eligibleVatCountries } from "@/core/constants/eligible-vat-countries";
import { getAddressName, Logger } from "@/core/utilities";
import { useMelissaAddresses } from "@/shared/account/composables/useMelissaAddresses";
import { useCountryISO3 } from "@/shared/common/composables/useCountryISO3";
import type {
  CountryRegionType,
  CountryType,
  MelissaResultType,
  MemberAddressType,
  QueryGetMelissaAddressesArgs,
} from "@/core/api/graphql/types";
import type { CountryConfigType } from "@/core/types/addressCountryConfigs";
import type { ItemSelectType } from "@/core/types/item-select";

const emit = defineEmits<IEmits>();
const props = withDefaults(defineProps<IProps>(), {
  countries: () => [],
});

interface IProps {
  modelValue?: MemberAddressType;
  disabled?: boolean;
  requiredEmail?: boolean;
  requiredPhone?: boolean;
  requiredCity?: boolean;
  withPersonalInfo?: boolean;
  countries?: CountryType[];
}
interface IEmits {
  (event: "update:modelValue", address: MemberAddressType): void;
  (event: "save", address: MemberAddressType): void;
}
const { t } = useI18n();
const countryISO3 = useCountryISO3(useLocalStorage<string>("countryCode", "").value);
const cities = ref<ItemSelectType[]>([]);
const districts = ref<ItemSelectType[]>([]);
const initialValues: MemberAddressType = {
  isDefault: false,
  isDefaultShippingAddressTL: false,
  isDefaultBillingAddressTL: false,
  isFavorite: false,
  countyTL: "",
  districtTL: "",
  departmentTL: "",
  buildingTL: "",
  dataAreaIdTL: "",
  eRPIDTL: "",
  firstName: "",
  lastName: "",
  email: "",
  organization: "",
  postalCode: "",
  countryCode: countryISO3 || "",
  countryName: "",
  regionId: "",
  regionName: "",
  city: "",
  line1: "",
  line2: "",
  phone: "",
  description: "",
  taxExemptNoTL: "",
  mailStopTL: "",
};
const isEditAddress = ref(true);

const { values, meta, errors, handleSubmit, setErrors, validate, resetForm } = useForm<MemberAddressType>({
  initialValues,
});

const { loadMelissaAddresses } = useMelissaAddresses();

const taxExemptNoFormOpen = ref(false);
const addressLine2FormOpen = ref(false);

const handleToggleTaxExemptNo = () => {
  taxExemptNoFormOpen.value = !taxExemptNoFormOpen.value;
};

const handleToggleAddressLine2 = () => {
  addressLine2FormOpen.value = !addressLine2FormOpen.value;
};

const slotsData = computed(() => ({
  setErrors,
  validate,
  reset: resetForm,
  save,
  errors,
  values,
  dirty: meta.value.dirty,
  valid: meta.value.valid,
  validated: meta.value.validated,
  pending: meta.value.pending,
  touched: meta.value.touched,
}));

const isJapan = computed<boolean>(() => country.value?.id === "JPN");
const isChina = computed<boolean>(() => country.value?.id === "CHN");

const emailRules = computed(() => {
  let rules = yup.string().trim().max(64).email().nullable();
  if (props.withPersonalInfo && props.requiredEmail) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const phoneRules = computed(() => {
  const regex = /^[0-9 ()+-]*$/;
  let rules = yup
    .string()
    .trim()
    .max(64)
    .nullable()
    .matches(regex, { message: t("common.messages.invalid_field_regex") });
  if (props.withPersonalInfo && props.requiredPhone) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const organizationRules = computed(() => {
  let rules = yup.string().max(128).nullable();
  if (country.value?.id === "JPN") {
    rules = rules.required();
  }

  return toTypedSchema(rules);
});

const cityRules = computed(() => {
  let rules = yup.string().trim().max(128).nullable();
  if (props.requiredCity) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const shouldTaxExemptNoBeDisplayed = computed(() => {
  return eligibleVatCountries.includes(country.value?.id || "");
});

const regionRules = computed(() => {
  // Do not use computed based on field value cause it may cause infinite loop
  const rules = yup
    .string()
    .nullable()
    .when("countryCode", {
      is: (value: string) => props.countries.find((item) => value === item.id)?.regions.length,
      then: (schema) => schema.required(),
    });
  return toTypedSchema(rules);
});

const firstNameRules = computed(() => {
  let rules = yup.string().trim().max(64).nullable();
  if (props.withPersonalInfo) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const lastNameRules = computed(() => {
  let rules = yup.string().trim().max(64).nullable();
  if (props.withPersonalInfo) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const country = computed<CountryType | undefined>({
  get: () => props.countries.find((item) => countryCode.value === item.id),
  set: (value?: CountryType) => {
    countryCode.value = value?.id ?? "";
    countryName.value = value?.name ?? "";
    regionId.value = "";
    regionName.value = "";
  },
});

const countryConfig = computed<CountryConfigType | undefined>(() => {
  if (country.value) {
    return countryConfigsMap[country.value.id] || countryConfigsMap["DEFAULT"];
  }

  return undefined;
});

const regions = computed<CountryRegionType[]>(() => country.value?.regions ?? []);
const melissaRegions = ref<CountryRegionType[]>([]);

const region = computed<CountryRegionType | undefined>({
  get: () => {
    if (regions.value.length === 0 && melissaRegions.value.length > 0) {
      return melissaRegions.value.find((item) => regionId.value === item.id);
    }

    return regions.value.find((item) => regionId.value === item.id || regionName.value === item.name);
  },
  set: (value?: CountryRegionType) => {
    regionId.value = value?.id ?? "";
    regionName.value = value?.name ?? "";
  },
});

const selectedCity = computed<ItemSelectType | undefined>({
  get: () => cities.value.find((item) => city.value === item.name),
  set: (value?: ItemSelectType) => {
    city.value = value?.name ?? "";
  },
});

const selectedDistrict = computed<ItemSelectType | undefined>({
  get: () => districts.value.find((item) => districtTL.value === item.name),
  set: (value?: ItemSelectType) => {
    districtTL.value = value?.name ?? "";
  },
});

const { value: email } = useField("email", emailRules);
const { value: city } = useField("city", cityRules);
const { value: phone } = useField("phone", phoneRules);
const { value: firstName } = useField("firstName", firstNameRules);
const { value: lastName } = useField("lastName", lastNameRules);
const { value: departmentTL } = useField("departmentTL", toTypedSchema(yup.string().max(128).nullable()));
const { value: buildingTL } = useField("buildingTL", toTypedSchema(yup.string().max(128).nullable()));
const { value: mailStopTL } = useField("mailStopTL", toTypedSchema(yup.string().max(128).nullable()));
const { value: taxExemptNoTL } = useField("taxExemptNoTL", toTypedSchema(yup.string().trim().max(128).nullable()));
const { value: postalCode } = useField("postalCode", toTypedSchema(yup.string().trim().max(32).required().nullable()));
const { value: countryCode } = useField("countryCode", toTypedSchema(yup.string().required().nullable()));
const { value: countryName } = useField("countryName", toTypedSchema(yup.string().max(128).nullable()));
const { value: countyTL } = useField("countyTL", toTypedSchema(yup.string().max(128).nullable()));
const { value: organization } = useField("organization", organizationRules);
const { value: districtTL } = useField("districtTL", toTypedSchema(yup.string().max(128).nullable()));
const { value: regionName } = useField("regionName", toTypedSchema(yup.string().max(128).nullable()));
const { value: regionId } = useField("regionId", regionRules);
const { value: line1 } = useField("line1", toTypedSchema(yup.string().trim().max(128).required().nullable()));
const { value: isDefaultBillingAddressTL } = useField("isDefaultBillingAddressTL", toTypedSchema(yup.boolean()));
const { value: isDefaultShippingAddressTL } = useField("isDefaultShippingAddressTL", toTypedSchema(yup.boolean()));

onMounted(() => {
  isEditAddress.value = !!props.modelValue?.line1;
});

function formatPostalCode(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value.replace(/[^0-9]/g, "");
  if (value.length > 3) {
    value = value.slice(0, 3) + "-" + value.slice(3, 7);
  }
  input.value = value;
  postalCode.value = value;
}

watch(postalCode, async (newPostalCode) => {
  if (isEditAddress.value) {
    isEditAddress.value = false;
    return;
  }

  if (newPostalCode && newPostalCode.length > 2) {
    city.value = "";
    regionId.value = "";
    melissaRegions.value = [];
    cities.value = [];

    try {
      const args: QueryGetMelissaAddressesArgs = {
        freeFormatInput: newPostalCode,
        countryCode: countryCode.value || undefined,
      };
      const result = await loadMelissaAddresses(args);

      if (result.length == 0) {
        return;
      }

      if (countryCode.value === "THA") {
        mapThailandFields(result);
      } else if (countryCode.value === "CHN") {
        mapChinaFields(result);
      } else if (countryCode.value === "TWN") {
        mapTaiwanFields(result);
      } else if (countryCode.value === "BRA") {
        mapBrazilFields(result);
      } else {
        mapFieldsBasedOnCountryConfig(result);
      }

      if (regions.value.length === 0) {
        melissaRegions.value.push({ id: result[0].administrativeArea!, name: result[0].administrativeArea! });
      }
    } catch (error) {
      console.error("Error fetching Melissa addresses:", error);
    }
  }
});

watch(countryCode, (newCountryCode) => {
  if (props.modelValue?.countryCode !== newCountryCode) {
    city.value = "";
    regionId.value = "";
    melissaRegions.value = [];
    postalCode.value = "";
    cities.value = [];
    districts.value = [];
  }
});

function mapFieldsBasedOnCountryConfig(melissaResult: MelissaResultType[]) {
  if (melissaResult.length == 1) {
    if (countryConfig.value?.cityLabel) {
      city.value = melissaResult[0].city || melissaResult[0].locality;
    }

    if (countryConfig.value?.stateLabel) {
      regionId.value = melissaResult[0].state || melissaResult[0].administrativeArea;
      regionName.value = melissaResult[0].state || melissaResult[0].administrativeArea;
    }

    if (countryConfig.value?.countyLabel) {
      countyTL.value = melissaResult[0].administrativeArea;
    }

    if (countryConfig.value?.districtLabel) {
      districtTL.value = melissaResult[0].locality;
    }
  } else {
    const uniqueCities = melissaResult
      .map((item, index) => ({
        id: index.toString(),
        name: item.city || item.locality,
      }))
      .filter((value, index, self) => value.name !== "" && self.findIndex((v) => v.name === value.name) === index);

    cities.value = uniqueCities as ItemSelectType[];
    if (countryConfig.value?.stateLabel) {
      regionId.value = melissaResult[0].state || melissaResult[0].administrativeArea;
      regionName.value = melissaResult[0].state || melissaResult[0].administrativeArea;
    }

    if (countryConfig.value?.countyLabel) {
      countyTL.value = melissaResult[0].administrativeArea;
    }
  }
}

function mapThailandFields(melissaResult: MelissaResultType[]) {
  if (melissaResult.length === 1) {
    districtTL.value = melissaResult[0].city || melissaResult[0].locality;
    regionId.value = melissaResult[0].administrativeArea;
    regionName.value = melissaResult[0].administrativeArea;
  } else {
    districts.value = melissaResult.map((item, index) => ({
      id: index.toString(),
      name: item.city || item.locality,
    })) as ItemSelectType[];
    regionId.value = melissaResult[0].administrativeArea;
    regionName.value = melissaResult[0].administrativeArea;
  }
}

function mapChinaFields(result: MelissaResultType[]) {
  if (result.length === 1) {
    city.value = result[0].locality;
    regionId.value = result[0].administrativeArea;
    regionName.value = result[0].administrativeArea;
    districtTL.value = result[0].dependentLocality;
  } else {
    cities.value = result.map((item, index) => ({
      id: index.toString(),
      name: item.city || item.locality,
    })) as ItemSelectType[];
    regionId.value = result[0].administrativeArea;
    regionName.value = result[0].administrativeArea;
    districtTL.value = result[0].dependentLocality;
  }
}

function mapTaiwanFields(result: MelissaResultType[]) {
  if (result.length === 1) {
    city.value = result[0].administrativeArea;
  } else {
    cities.value = result.map((item, index) => ({
      id: index.toString(),
      name: item.administrativeArea,
    })) as ItemSelectType[];
  }
}

function mapBrazilFields(melissaResult: MelissaResultType[]) {
  if (melissaResult.length === 1) {
    if (countryConfig.value?.cityLabel) {
      city.value = melissaResult[0].city || melissaResult[0].locality;
    }

    if (countryConfig.value?.stateLabel) {
      const rawRegion = melissaResult[0].state || melissaResult[0].administrativeArea;
      const capitalizedRegion = rawRegion ? rawRegion.toUpperCase() : "";
      regionId.value = capitalizedRegion;
      regionName.value = capitalizedRegion;
    }

    if (countryConfig.value?.countyLabel) {
      countyTL.value = melissaResult[0].administrativeArea;
    }
  }
}

const save = handleSubmit((address) => {
  const newAddress: MemberAddressType = { ...address, name: getAddressName(address) };
  emit("update:modelValue", newAddress);
  emit("save", newAddress);
}, Logger.debug);

watch(
  () => props.modelValue,
  (modelValue) => {
    resetForm({ values: modelValue });
  },
  { deep: true, immediate: true },
);
</script>
