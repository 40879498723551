import { eagerComputed, useLocalStorage } from "@vueuse/core";
import { remove } from "lodash";
import { computed, readonly, ref } from "vue";
import {
  getMe,
  inviteUser as _inviteUser,
  registerAccount,
  registerByInvitation,
  requestPasswordReset,
  resetPasswordByToken,
  updatePersonalData,
  changePassword as _changePassword,
  sendVerifyEmail as _sendVerifyEmail,
  confirmEmailByToken,
  updateContact,
  updateUsername as _updateUsername,
} from "@/core/api/graphql/account";
import { anonymizeContact } from "@/core/api/graphql/account/mutations/deleteAccount";
import { resetInitialPasswordByToken } from "@/core/api/graphql/account/mutations/resetInitialPasswordByToken";
import { useAuth } from "@/core/composables/useAuth";
import { globals } from "@/core/globals";
import { Logger } from "@/core/utilities";
import {
  TabsType,
  useBroadcast,
  userLockedEvent,
  userReloadEvent,
  passwordExpiredEvent,
  reloadAndOpenMainPage,
} from "@/shared/broadcast";
import { useModal } from "@/shared/modal";
import PasswordExpirationModal from "../components/password-expiration-modal.vue";
import type {
  AccountCreationResultType,
  CustomIdentityResultType,
  IdentityResultType,
  InputConfirmEmailType,
  InputDeleteContactTypeExtension,
  InputInviteUserType,
  InputRegisterByInvitationType,
  InputUpdateUsernameType,
  Organization,
  UserType,
} from "@/core/api/graphql/types";
import type {
  ForgotPassword,
  RegisterOrganization,
  ResetPassword,
  ChangePassword,
  SignMeUp,
  UserPersonalData,
} from "@/shared/account";

const loading = ref(false);
const loadingChangePassword = ref(false);

const user = ref<UserType>();

const isAuthenticated = computed<boolean>(() => !!user.value?.userName && user.value.userName !== "Anonymous");
const isCorporateMember = computed<boolean>(() => !!user.value?.contact?.organizationId);
const organization = eagerComputed<Organization | null>(
  () =>
    user.value?.contact?.organizations?.items?.find((item) => item.id === user.value?.contact?.organizationId) ?? null,
);
const operator = computed<UserType | null>(() => user.value?.operator ?? null);
const displayName = computed<string>(() => {
  const lastName = user.value?.contact?.lastName;
  if (lastName) {
    const nameContainsAsianCharacters = /[\u3400-\u9FBF]/.test(lastName);
    if (globals.cultureName === "ja-JP") {
      if (nameContainsAsianCharacters) {
        return `${user.value?.contact?.lastName}${user.value?.contact?.firstName}様`;
      } else {
        return `${user.value?.contact?.lastName} ${user.value?.contact?.firstName} 様`;
      }
    }
    if (globals.cultureName === "zh-CN") {
      if (nameContainsAsianCharacters) {
        return `${user.value?.contact?.lastName}${user.value?.contact?.firstName}`;
      } else {
        return `${user.value?.contact?.lastName} ${user.value?.contact?.firstName}`;
      }
    }
    if (nameContainsAsianCharacters) {
      return `${user.value?.contact?.lastName}${user.value?.contact?.firstName}`;
    }

    return user.value?.contact?.fullName ?? "";
  }
  return "";
});

const greetingName = computed<string>(() => {
  const lastName = user.value?.contact?.lastName;
  if (lastName) {
    const nameContainsAsianCharacters = /[\u3400-\u9FBF]/.test(lastName);
    if (globals.cultureName === "ja-JP" || globals.cultureName === "zh-CN" || nameContainsAsianCharacters) {
      return displayName.value;
    }

    return user.value?.contact?.firstName ?? "";
  }
  return "";
});

const greeting = computed<string>(() => {
  const nameContainsAsianCharacters = /[\u3400-\u9FBF]/.test(user.value?.contact?.lastName || "");
  return globals.cultureName === "ja-JP" || globals.cultureName === "zh-CN" || nameContainsAsianCharacters
    ? "common.messages.hello"
    : "common.messages.hi";
});

interface IPasswordExpirationEntry {
  userId: string;
  date: Date;
}

export function useUser() {
  const broadcast = useBroadcast();
  const { refresh } = useAuth();
  const { openModal, closeModal } = useModal();

  const changePasswordReminderDates = useLocalStorage<IPasswordExpirationEntry[]>(
    "vcst-password-expire-reminder-date",
    [],
  );

  function handlePasswordExpiration(): void {
    if (!user.value?.passwordExpiryInDays) {
      return;
    }

    const userPasswordExpirationEntry = changePasswordReminderDates.value.find(
      (entry) => entry.userId === user.value!.id,
    );

    if (userPasswordExpirationEntry && new Date(userPasswordExpirationEntry.date) > new Date()) {
      return;
    }

    openModal({
      component: PasswordExpirationModal,

      props: {
        expiryInDays: user.value?.passwordExpiryInDays,

        async onConfirm(): Promise<void> {
          if (userPasswordExpirationEntry) {
            remove(changePasswordReminderDates.value, (entry) => entry.userId === userPasswordExpirationEntry.userId);
          }

          closeModal();

          await globals.router.replace({ name: "ChangePassword" });
        },

        onDismiss(): void {
          const nextDate = new Date();
          nextDate.setDate(nextDate.getDate() + 1);

          if (userPasswordExpirationEntry) {
            userPasswordExpirationEntry.date = nextDate;
          } else {
            changePasswordReminderDates.value.push({
              userId: user.value!.id,
              date: nextDate,
            });
          }

          closeModal();
        },
      },
    });
  }

  function checkPermissions(...permissions: string[]): boolean {
    let access = !!user.value?.isAdministrator;

    if (!access) {
      access = permissions.every((permission) => user.value?.permissions?.includes(permission));
    }

    return access;
  }

  async function fetchUser(options: { withBroadcast?: boolean } = {}) {
    const { withBroadcast = false } = options;

    try {
      loading.value = true;

      user.value = await getMe();

      handlePasswordExpiration();

      if (withBroadcast) {
        void broadcast.emit(userReloadEvent);
      }

      if (user.value?.forcePasswordChange || user.value?.passwordExpired) {
        void broadcast.emit(passwordExpiredEvent);
      }

      if (user.value?.lockedState) {
        void broadcast.emit(userLockedEvent, undefined, TabsType.ALL);
      }
    } catch (e) {
      Logger.error(`${useUser.name}.${fetchUser.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function updateUser(personalData: UserPersonalData): Promise<void> {
    try {
      loading.value = true;

      await updateContact({
        ...personalData,
        id: user.value!.contact!.id,
        organizations: user.value?.contact?.organizations?.items?.map((item) => item.id),
      });

      await fetchUser({ withBroadcast: true });
    } catch (e) {
      Logger.error(`${useUser.name}.${updatePersonalData.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function deleteUser(anonymizeContactInput: InputDeleteContactTypeExtension): Promise<boolean> {
    try {
      loading.value = true;

      // Assuming there's an API function to delete a user
      await anonymizeContact({
        ...anonymizeContactInput,
      });

      // Clear the user data if the deleted user is the current user
      if (user.value?.id === anonymizeContactInput.contactId) {
        user.value = undefined;
      }

      return true;
    } catch (e) {
      Logger.error(`${useUser.name}.${deleteUser.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function updateUsername(updateUsernameInput: InputUpdateUsernameType): Promise<void> {
    try {
      loading.value = true;

      await _updateUsername({
        ...updateUsernameInput,
      });

      await fetchUser({ withBroadcast: true });
    } catch (e) {
      Logger.error(`${updateUsername.name}.${_updateUsername.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function confirmEmail(payload: InputConfirmEmailType): Promise<CustomIdentityResultType> {
    loading.value = true;

    try {
      return await confirmEmailByToken(payload);
    } catch (e) {
      Logger.error(`${useUser.name}.${confirmEmail.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function registerUser(
    payload: SignMeUp,
    languageCode: string,
    currency: string,
    captchaCode: string,
  ): Promise<AccountCreationResultType> {
    const { storeId } = globals;

    try {
      loading.value = true;

      const resultData = await registerAccount({
        storeId,
        account: {
          username: payload.userName,
          password: payload.password,
          email: payload.email,
        },
        contact: {
          firstName: payload.firstName,
          lastName: payload.lastName,
        },
        languageCode: languageCode,
        currency: currency,
        captchaCode: captchaCode,
      });

      return resultData.result!;
    } catch (e) {
      Logger.error(`${useUser.name}.${registerUser.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function registerOrganization(payload: RegisterOrganization): Promise<AccountCreationResultType> {
    const { storeId } = globals;

    try {
      loading.value = true;

      const resultData = await registerAccount({
        storeId,
        account: {
          username: payload.userName,
          password: payload.password,
          email: payload.email,
        },
        contact: {
          firstName: payload.firstName as string,
          lastName: payload.lastName as string,
        },
        organization: {
          name: payload.organizationName as string,
        },
      });

      return resultData.result!;
    } catch (e) {
      Logger.error(`${useUser.name}.${registerOrganization.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function forgotPassword(payload: ForgotPassword): Promise<boolean> {
    try {
      loading.value = true;

      return await requestPasswordReset({
        loginOrEmail: payload.email,
        urlSuffix: payload.resetPasswordUrlPath,
        captchaCode: payload.captchaCode,
      });
    } catch (e) {
      Logger.error(`${useUser.name}.${forgotPassword.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function resetPassword(payload: ResetPassword): Promise<IdentityResultType> {
    try {
      loading.value = true;

      return await resetPasswordByToken({
        userId: payload.userId,
        token: payload.token,
        newPassword: payload.password,
      });
    } catch (e) {
      Logger.error(`${useUser.name}.${resetPassword.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function resetPasswordInitial(payload: ResetPassword): Promise<IdentityResultType> {
    try {
      loading.value = true;
      return await resetInitialPasswordByToken({
        userId: payload.userId,
        token: payload.token,
        newPassword: payload.password,
      });
    } catch (e) {
      Logger.error(`${useUser.name}.${resetPassword.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }
  async function changePassword(payload: ChangePassword): Promise<IdentityResultType> {
    try {
      loadingChangePassword.value = true;

      return await _changePassword({
        userId: payload.userId,
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
      });
    } catch (e) {
      Logger.error(`${useUser.name}.${resetPassword.name}`, e);
      return { succeeded: false };
    } finally {
      loadingChangePassword.value = false;
    }
  }

  async function inviteUser(payload: InputInviteUserType): Promise<CustomIdentityResultType> {
    try {
      return await _inviteUser(payload);
    } catch (e) {
      Logger.error(`${useUser.name}.${inviteUser.name}`, e);
      throw e;
    }
  }

  async function registerByInvite(payload: InputRegisterByInvitationType): Promise<CustomIdentityResultType> {
    loading.value = true;

    try {
      return await registerByInvitation(payload);
    } catch (e) {
      Logger.error(`${useUser.name}.${registerByInvite.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function sendVerifyEmail(userId: string): Promise<boolean | undefined> {
    loading.value = true;

    try {
      return await _sendVerifyEmail(userId);
    } catch (e) {
      Logger.error(`${useUser.name}.${sendVerifyEmail.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function switchOrganization(organizationId: string): Promise<void> {
    loading.value = true;

    try {
      await refresh(organizationId);

      localStorage.setItem(`organization-id-${user.value?.userName}`, organizationId);

      void broadcast.emit(reloadAndOpenMainPage, null, TabsType.ALL);
    } catch (e) {
      Logger.error(switchOrganization.name, e);
    } finally {
      loading.value = false;
    }
  }

  return {
    isAuthenticated,
    displayName,
    greetingName,
    greeting,
    isCorporateMember,
    isMultiOrganization: computed(
      () => user.value?.contact?.organizations?.items && user.value?.contact?.organizations?.items?.length > 1,
    ),
    organization,
    operator,
    checkPermissions,
    fetchUser,
    updateUser,
    updateUsername,
    deleteUser,
    confirmEmail,
    registerUser,
    registerOrganization,
    forgotPassword,
    resetPassword,
    resetPasswordInitial,
    inviteUser,
    registerByInvite,
    changePassword,
    sendVerifyEmail,
    switchOrganization,
    loading: readonly(loading),
    loadingChangePassword: readonly(loadingChangePassword),
    user: computed({
      get() {
        if (!user.value) {
          throw new Error("User is missing.");
        }

        return user.value!;
      },

      set() {
        throw new Error("User change is not available.");
      },
    }),
  };
}
