<template>
  <form @submit="onSubmit">
    <!-- Errors block -->
    <VcAlert
      v-for="error in translatedErrors.filter((e) => e.code !== IdentityErrors.PASSWORD_RESET_REQUIRED)"
      :key="error.code"
      class="mb-4"
      color="warning"
      size="sm"
      variant="outline-dark"
      closable
      @close="resetErrors"
    >
      <span v-if="error?.code === IdentityErrors.USER_IS_LOCKED_OUT"> {{ error.translation }}</span>

      <span v-else-if="error?.code === IdentityErrors.PASSWORD_EXPIRED" class="flex place-items-center justify-between">
        <span>
          {{ error.translation }}
        </span>
        <!-- Keep the A tag to reinitialize the app -->
        <a href="/change-password" class="text-sm font-semibold text-blue-700 hover:text-blue-500">
          {{ $t("common.buttons.set_new_password") }}
        </a>
      </span>
      <span v-else>
        {{ error.translation }}
      </span>
    </VcAlert>

    <VcInput
      v-model.trim="email"
      name="email"
      class="mb-4"
      :label="$t('common.labels.email_address')"
      :placeholder="$t('common.labels.email_address')"
      :disabled="loading"
      :message="validationErrors.email"
      :error="!!validationErrors.email"
      autocomplete="email"
    />

    <VcInput
      v-model="password"
      :label="$t('common.labels.password')"
      :placeholder="$t('common.placeholders.password')"
      :disabled="loading"
      type="password"
      :message="validationErrors.password"
      :error="!!validationErrors.password"
      autocomplete="password"
    />

    <VcButton
      color="primary"
      size="base"
      variant="solid"
      type="submit"
      class="mt-6 w-full rounded-md p-2 text-base font-semibold"
      :loading="loading"
    >
      <span>{{ $t("shared.account.sign_in_form.login_button") }}</span>
    </VcButton>

    <div class="mt-8 text-center text-[--body-text-color]">
      <div class="mb-4">
        <router-link
          to="/forgot-password"
          class="text-base text-[--link-color] hover:text-[--link-hover-color]"
          @click="closeForm"
        >
          {{ $t("common.buttons.reset_password") }}
        </router-link>
        {{ $t("common.labels.or") }}
        <router-link
          v-t="'shared.account.sign_in_form.forgot_id'"
          to="/forgot-id"
          class="text-base text-[--link-color] hover:text-[--link-hover-color]"
          @click="closeForm"
          >{{ $t("shared.account.sign_in_form.forgot_id") }}</router-link
        >
      </div>
      {{ $t("pages.sign_in.no_account_yet") }}
      <router-link
        to="/sign-up"
        class="text-base text-[--link-color] hover:text-[--link-hover-color]"
        @click="closeForm"
      >
        {{ $t("common.buttons.register") }}
      </router-link>
    </div>
  </form>
</template>

<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/yup";
import { useField, useForm } from "vee-validate";
import { ref, toRef, watch } from "vue";
import { useRouter } from "vue-router";
import { object, string } from "yup";
import { useErrorsTranslator } from "@/core/composables";
import { IdentityErrors } from "@/core/enums";
import { useSignMeIn } from "@/shared/account/composables";
const emit = defineEmits(["close-form"]);

const mainElement = document.getElementById("maincontent");

const closeForm = () => {
  emit("close-form"); // Emit event to close the form
  // Check if mainElement is not null before removing the class
  if (mainElement) {
    mainElement.classList.remove("mobile-menu-open");
  }
};

const schema = toTypedSchema(
  object({
    email: string().required(),
    password: string().required(),
  }),
);
const router = useRouter();
const {
  errors: validationErrors,
  meta,
  handleSubmit,
} = useForm({
  validationSchema: schema,
});

const { value: email } = useField<string>("email");
const { value: password } = useField<string>("password");

const rememberMe = ref(false);

const model = toRef({ email, password, rememberMe });

const { errors, loading, signIn, resetErrors } = useSignMeIn(model);

const { translatedErrors } = useErrorsTranslator("shared.account.sign_in_form.errors", errors);

const onSubmit = handleSubmit(async () => {
  await signIn().then(() => {
    if ((errors.value?.length ?? 0) > 0) {
      const errorCode = (errors?.value ?? [])[0]?.code;

      if (errorCode === IdentityErrors.PASSWORD_RESET_REQUIRED) {
        router.push({ name: "InfoResetPasswordInitial", query: { email: email.value } });
      } else {
        console.log(errorCode);
      }
    }
  });
});

watch(meta, (value) => {
  if (value.touched) {
    resetErrors();
  }
});
</script>

<style lang="scss">
.vc-alert {
  &--outline-dark {
    .vc-alert__close-button {
      .vc-icon {
        @apply w-3 h-3;
      }
    }
  }
}
</style>
