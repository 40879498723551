<template>
  <div class="flex flex-col gap-y-3">
    <label v-if="formElement.properties.label">{{ formElement.properties.label }} </label>
    <div v-if="!isTrueFalseItem()" class="flex flex-col gap-3 md:flex-row">
      <template v-for="(item, index) in formElement.properties.items" :key="index">
        <VcCheckbox :name="formElement.key" :value="item.value" :checked="item.checked">
          <p v-dompurify-html="item.caption"></p>
        </VcCheckbox>
      </template>
    </div>
    <div v-else class="flex flex-col gap-3 md:flex-row">
      <!-- assume first item is true -->
      <VcCheckbox
        :name="formElement.key"
        :value="props.formElement.properties.items[0].value"
        :model-value="isTrueChecked"
        @change="onTrueChanged"
      >
        <p v-dompurify-html="formElement.properties.items[0].caption"></p>
      </VcCheckbox>
      <VcCheckbox
        hidden="true"
        :name="formElement.key"
        :value="props.formElement.properties.items[1].value"
        :model-value="!isTrueChecked"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from "vue";
import type { ExtendedFormElement } from "../types";

interface IProps {
  formElement: ExtendedFormElement;
}

const props = defineProps<IProps>();
const model = defineModel<string>();

//if the checkbox element has only to options assume it's a true/false scenario
//there were 2 items defined in the cms for both true & false because optimizely only accepts defined values,
//but we only want to display it as one checkbox
function isTrueFalseItem() {
  if (props.formElement.properties.items.length !== 2) {
    return false;
  }
  return props.formElement.properties.items[0].caption === props.formElement.properties.items[1].caption;
}

const isTrueChecked = ref<boolean>();

onBeforeMount(() => {
  isTrueChecked.value = props.formElement.properties.items[0].checked;
  model.value = isTrueChecked.value
    ? props.formElement.properties.items[0].value
    : props.formElement.properties.items[1].value;
});

// FIXME: https://virtocommerce.atlassian.net/browse/ST-3812
/* eslint-disable @typescript-eslint/no-explicit-any */
function onTrueChanged(isTrue: boolean | any[]) {
  isTrueChecked.value = !!isTrue;
  model.value = isTrue ? props.formElement.properties.items[0].value : props.formElement.properties.items[1].value;
}
</script>
