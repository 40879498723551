<template>
  <div v-if="isManualsRoute" class="flex justify-center border-t border-[--color-neutral-a4] p-6 font-medium">
    <div class="flex items-center gap-x-3">
      <VcIcon class="sustainability-icon" name="leaf" size="md" aria-hidden="true" />
      <div>
        {{ $t("pages.manuals_portal.portal_built_to_help_save_natural_resources") }} <br class="hidden md:block" />
        {{ $t("pages.manuals_portal.learn_more") }}
        <a :href="susteinabilityLink" class="text-[--color-link] hover:text-[--color-neutral-a1]">{{
          $t("pages.manuals_portal.sustainability_journey")
        }}</a
        >.
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
const route = useRoute();
const isManualsRoute = route.path === "/manuals";
const susteinabilityLink = "#";
</script>

<style lang="scss" scoped>
.sustainability-icon {
  --vc-icon-color: var(--color-alert-d0);
}
</style>
