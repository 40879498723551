import { syncRefs, useAsyncState, useLocalStorage } from "@vueuse/core";
import { ref, toValue } from "vue";
import { updateContact, getMe, useMergeCartMutation } from "@/core/api/graphql";
import { useAuth } from "@/core/composables/useAuth";
import { useCurrency } from "@/core/composables/useCurrency";
import { useLanguages } from "@/core/composables/useLanguages";
import { TabsType, openReturnUrl, useBroadcast } from "@/shared/broadcast";
import { useShortCart } from "@/shared/cart/composables";
import type { IdentityErrorType, InputUpdateContactTypeExtension, UserType } from "@/core/api/graphql/types";
import type { SignMeIn } from "@/shared/account/types";
import type { MaybeRefOrGetter } from "vue";

export function useSignMeIn(payload: MaybeRefOrGetter<SignMeIn>) {
  const { errors: authErrors, authorize } = useAuth();
  const broadcast = useBroadcast();
  const { cart } = useShortCart();
  const { mutate: mergeCart } = useMergeCartMutation();
  const { supportedLanguages, saveLocale, currentLanguage } = useLanguages();
  const { supportedCurrencies, saveCurrencyCode, currentCurrency } = useCurrency();
  const savedLocale = useLocalStorage<string>("locale", "");
  const savedCurrencyCode = useLocalStorage<string | null>("currency", "");

  const { isLoading: loading, execute: signIn } = useAsyncState(
    async () => {
      const { email, password } = toValue(payload);
      await authorize(email, password, currentLanguage.value.cultureName, currentCurrency.value.code);

      const me = await getMe();
      await mergeCart({ command: { userId: me!.id, secondCartId: cart.value!.id } });
      let isUpdateContactLanguageNeeded = false;
      let isUpdateContactCurrencyNeeded = false;

      if (me.contact?.defaultLanguage) {
        const contactLanguage = supportedLanguages.value.find(
          (item) => item.cultureName === me.contact!.defaultLanguage,
        );

        if (contactLanguage) {
          saveLocale(contactLanguage.twoLetterLanguageName, false);
        }
      } else if (savedLocale.value) {
        isUpdateContactLanguageNeeded = true;
      }

      if (me.contact?.currencyCode) {
        const contactCurrency = supportedCurrencies.value.find((item) => item.code === me.contact!.currencyCode);

        if (contactCurrency) {
          saveCurrencyCode(contactCurrency.code, false);
        }
      } else if (savedCurrencyCode.value) {
        isUpdateContactCurrencyNeeded = true;
      }

      if (isUpdateContactLanguageNeeded || isUpdateContactCurrencyNeeded) {
        await updateLanguageAndCurrency(isUpdateContactCurrencyNeeded, isUpdateContactLanguageNeeded, me);
      }

      broadcast.emit(openReturnUrl, undefined, TabsType.ALL);
    },
    null,
    { immediate: false },
  );

  async function updateLanguageAndCurrency(
    isUpdateContactCurrencyNeeded: boolean,
    isUpdateContactLanguageNeeded: boolean,
    me: UserType,
  ) {
    const contactData: InputUpdateContactTypeExtension = {
      defaultLanguage: isUpdateContactLanguageNeeded
        ? supportedLanguages.value.find((lang) => lang.twoLetterLanguageName === savedLocale.value)?.cultureName
        : me.contact?.defaultLanguage,
      currencyCode: isUpdateContactCurrencyNeeded
        ? supportedCurrencies.value.find((currency) => currency.code === savedCurrencyCode.value)?.code
        : me.contact?.currencyCode,
      firstName: me.contact?.firstName ?? "",
      lastName: me.contact?.lastName ?? "",
      id: me.contact?.id ?? "",
      isOverride: false,
    };

    await updateContact(contactData);
  }

  const errors = ref<IdentityErrorType[]>();

  syncRefs(authErrors, errors);

  function resetErrors() {
    errors.value = [];
  }

  return {
    errors,
    loading,
    signIn,
    resetErrors,
    authorize,
  };
}
