import { graphqlClient } from "../../../client";
import requestPasswordResetExtensionDocument from "./requestPasswordResetExtension.graphql";
import type { Query, QueryRequestPasswordResetExtensionArgs } from "@/core/api/graphql/types";

export async function requestPasswordReset(payload: QueryRequestPasswordResetExtensionArgs): Promise<boolean> {
  const { data } = await graphqlClient.query<
    Required<Pick<Query, "requestPasswordResetExtension">>,
    QueryRequestPasswordResetExtensionArgs
  >({
    query: requestPasswordResetExtensionDocument,
    variables: {
      ...payload,
    },
  });

  return data.requestPasswordResetExtension;
}
