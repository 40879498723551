import { computed, watch } from "vue";
import { useCurrency } from "@/core/composables/useCurrency";
import { useSlugInfo } from "@/shared/common";
import type { IFooterBlock, IFooterCurrencyData, IAdditionalLink } from "@/shared/thorlabs/blocks";

export function useFooterBlock(): {
  footerBlock: ReturnType<typeof computed<IFooterCurrencyData | undefined>>;
  chinaDomainIdentifier: IAdditionalLink | undefined;
  isChinaDomain: () => boolean;
} {
  const { currentCurrency } = useCurrency();
  const { slugInfo, hasContent, contentResult, fetchContent } = useSlugInfo("Footer");

  let chinaDomainIdentifier: IAdditionalLink | undefined = undefined;

  watch(slugInfo, async () => {
    if (hasContent.value) {
      await fetchContent();
    }
  });

  const footerBlock = computed<IFooterCurrencyData | undefined>(() => {
    const footerBlockData: IFooterBlock = JSON.parse(contentResult?.value?.page?.content ?? "{}") as IFooterBlock;

    const currencyKey = Object.keys(footerBlockData).find((key) =>
      key.toLowerCase().includes(currentCurrency.value?.code.toLowerCase()),
    ) as keyof IFooterBlock;

    chinaDomainIdentifier = footerBlockData.chinaDomainIdentifier ?? undefined;

    if (currencyKey && typeof footerBlockData[currencyKey] === "object") {
      const footerDataForCurrentCurrency: IFooterCurrencyData = footerBlockData[currencyKey] as IFooterCurrencyData;
      return footerDataForCurrentCurrency;
    }

    return undefined;
  });

  function isChinaDomain(): boolean {
    return window.location.hostname.includes("thorlabschina");
  }

  return {
    footerBlock,
    chinaDomainIdentifier,
    isChinaDomain,
  };
}
