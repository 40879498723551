import type { CountryConfigType } from "../types/addressCountryConfigs";

export const countryConfigsMap: Record<string, CountryConfigType> = {
  USA: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.state",
    zipLabel: "common.labels.zip",
    districtLabel: "",
    countyLabel: "",
  },
  FRA: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  GBR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  SGP: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  CAN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  BLZ: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "common.labels.county",
  },
  COL: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.departamento",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  BRA: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.state",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  CHN: {
    addressLabel: "common.labels.detailed_address",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  DZA: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  AUS: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.state",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  GLP: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  IND: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.state",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  ISR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  IDN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  IRQ: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  JOR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  KEN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  KGZ: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  KWT: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  LBR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  LBN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  MEX: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  MNG: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  NGA: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  NZL: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  OMN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  PAK: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.state",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  PRI: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  SAU: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  AND: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  ALB: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  ARM: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  AUT: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  BLR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  BEL: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  BIH: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  BGR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  HRV: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  CYP: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  CZE: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  DEU: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  GEO: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  GRC: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  HUN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  ITA: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  IRL: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  KAZ: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  LIE: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  LUX: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  MKD: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  MLT: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  MCO: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  MNE: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  NLD: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  PRT: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  ROU: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "common.labels.county",
  },
  RUS: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  SMR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  SVN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  SRB: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  CHE: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  TUR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  UKR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  SWE: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  DNK: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  FIN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  NOR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  ISL: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  POL: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  LTU: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  LVA: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  EST: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.state",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  ARG: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  BOL: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  CHL: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.region",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  CRI: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  ECU: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.departamento",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  SLV: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.departamento",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  GUF: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  GTM: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.departamento",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  GUY: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.region",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  HND: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.state",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  NIC: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.departamento",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  PAN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  PRY: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.departamento",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  PER: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.departamento",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  SUR: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "common.labels.county",
  },
  URY: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.departamento",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  VEN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.state",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  JPN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city_jp",
    stateLabel: "common.labels.prefecture",
    zipLabel: "common.labels.postal_code_jp",
    districtLabel: "",
    countyLabel: "",
  },
  MYS: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  THA: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.sub_district",
    stateLabel: "common.labels.province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  TWN: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  VNM: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.city_or_province",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.ward_district",
    countyLabel: "",
  },
  PHL: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city_municipality",
    stateLabel: "common.labels.province_if_applicable",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  HKG: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "common.labels.district",
    countyLabel: "",
  },
  MAC: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
  DEFAULT: {
    addressLabel: "common.labels.address_line1",
    cityLabel: "common.labels.city",
    stateLabel: "common.labels.state",
    zipLabel: "common.labels.postal_code",
    districtLabel: "",
    countyLabel: "",
  },
};

export const MUCCountryCodes = [
  "AND", // Andorra
  "ALB", // Albania
  "ARM", // Armenia
  "AUT", // Austria
  "BLR", // Belarus
  "BEL", // Belgium
  "BIH", // Bosnia and Herzegovina
  "BGR", // Bulgaria
  "HRV", // Croatia
  "CYP", // Cyprus
  "CZE", // Czech Republic
  "DEU", // Germany
  "GEO", // Georgia
  "GRC", // Greece
  "HUN", // Hungary
  "ITA", // Italy
  "IRL", // Ireland
  "KAZ", // Kazakhstan
  "LIE", // Liechtenstein
  "LUX", // Luxembourg
  "MKD", // North Macedonia
  "MLT", // Malta
  "MCO", // Monaco
  "MNE", // Montenegro
  "NLD", // Netherlands
  "PRT", // Portugal
  "ROU", // Romania
  "RUS", // Russia
  "SMR", // San Marino
  "SVN", // Slovenia
  "SRB", // Serbia
  "CHE", // Switzerland
  "TUR", // Turkey
  "UKR", // Ukraine
];
