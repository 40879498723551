<template>
  <div class="vc-container w-full">
    <div class="vc-container__wrapper">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.vc-container {
  @apply grow bg-[--body-bg-color] px-4 md:pb-9 lg:px-8 md:max-w-[994px];

  &__wrapper {
    @apply mx-auto;
  }
}
</style>
