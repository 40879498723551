import { addMethod, string } from "yup";
import type { I18n } from "@/i18n";

function addCustomValidations(i18n: I18n) {
  addMethod(string, "email", function validateEmail() {
    return this.matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      message: i18n.global.t("common.messages.email_is_not_correct"),
      name: "email",
      excludeEmptyString: true,
    });
  });
}

export { addCustomValidations };
